import React from "react";
import {useAdd, useDecreaseQuantity, useIncreaseQuantity, useRemoveCart, useCartReset} from "../actions/index";
import {useProductfromCart} from "../selector";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import Price from "./Price";
import {showSuccessSnackbar} from "../actions/ui";
import {useDispatch} from "react-redux";
import BoxDietDates from "./BoxDiet/BoxDietDates";
import {useAddBoxDiet} from "../actions";
import BoxDietDatesSelectDialog from "./BoxDiet/BoxDietDatesSelectDialog";
import {Tooltip} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({

    restaurant_menu_item__actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    restaurant_menu_item__actions_name: {
        fontWeight: 700,
    },
    restaurant_menu_item__actions_description: {
        minHeight: 56,
        maxHeight: 56,
        marginTop: 6,
        display: 'block',
        overflow: 'hidden',
        marginBottom: 10,
        textOverflow: 'ellipsis',
        wordWrap: 'break-word'
    },
    restaurant_menu_item_box_diet: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

function RestaurantMenuItem({menuItem, onBoxDietSelectClick}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const removeCart = useRemoveCart();
    const Add = useAdd();
    const resetCart = useCartReset();
    const AddBoxDiet = useAddBoxDiet();
    const increaseQuantity = useIncreaseQuantity();
    const decreaseQuantity = useDecreaseQuantity();
    const product = useProductfromCart(menuItem.id);
    const [quantity, setQuantity] = React.useState(0);

    const [boxDietStartDate, setBoxDietStartDate] = React.useState(null);

    const [boxDietEndDate, setBoxDietEndDate] = React.useState(null);

    const [boxDietRangeSelected, setBoxDietRangeSelected] = React.useState([null, null]);

    const [boxDietDeliveryDays, setBoxDietDeliveryDays] = React.useState([]);

    const [isDialogShown, setIsDialogShown] = React.useState(false);

    function onDialogClosed(){
        setIsDialogShown(false);
    }

    React.useEffect(() => {
        product && setQuantity(product.quantity);
        console.log('cart product: ', product);
    }, [product]);


    function onDatesRangeSelected(dateStart, dateEnd, deliveryDates) {
        //console.log('menuItem dates: ', datesRange);
        console.log('date start selected: ', dateStart);
        console.log('date end selected: ', dateEnd);
        console.log('delivery dates selected: ', deliveryDates);
        setBoxDietStartDate(dateStart);
        setBoxDietEndDate(dateEnd);
        setBoxDietRangeSelected([dateStart, dateEnd ]);
        setBoxDietDeliveryDays(deliveryDates);

        handleBoxDietIncrease(dateStart, dateEnd, deliveryDates);
    }

    function handleIncrease() {
        if (product === null) {
            if(!menuItem.is_box_diet){
                Add(menuItem);
            } else {
                onBoxDietSelectClick(menuItem);
            }

        } else {
            dispatch(showSuccessSnackbar("Dodano 1 sztukę produktu", "info"));
            increaseQuantity(menuItem.id);
        }
    }

    function handleBoxDietIncrease(dateStart, dateEnd, deliveryDates){
         if(!validateDatesRange(dateStart, dateEnd)){
                    dispatch(showSuccessSnackbar('Błędny zakres dat dostaw diety pudełkowej.'));
                } else {
                    AddBoxDiet(menuItem, {
                        startDate: dateStart.getTime(),
                        endDate: dateEnd.getTime()
                        } ,
                        deliveryDates
                    );
                }
    }

    function validateDatesRange(dateStart, dateEnd){
        console.log('validation start date ', dateStart);
        console.log('validation end date type: ', dateEnd);
        return dateStart instanceof Date && dateEnd instanceof Date;
    }

    function handleDecrease() {
        if (product !== null) {
            if (product.quantity === 1) {
                removeCart(menuItem.id);
                setQuantity(0);
            } else {
                dispatch(showSuccessSnackbar("Zmniejszono 1 sztukę produktu", "info"));
                decreaseQuantity(menuItem.id);
            }
        }
    }

    return (
        <div>
            <Card>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={menuItem.name}
                        height="140"
                        image={menuItem.image_filename}
                        title={menuItem.name}
                    />
                </CardActionArea>
                <CardActions>
                    <div className={classes.restaurant_menu_item__actions}>
                        <Tooltip title={menuItem.description} arrow>
                            <div>
                                <Typography variant="body2" color="textSecondary" component="p" className={classes.restaurant_menu_item__actions_name}>
                                    {menuItem.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p" className={classes.restaurant_menu_item__actions_description}>
                                    {menuItem.description}
                                </Typography>
                                <Typography><Price value={menuItem.price}/></Typography>
                            </div>
                        </Tooltip>

                        <div className={classes.restaurant_menu_item__actions_icon}>
                            <IconButton  aria-label="Dodaj jedną sztukę"  color="primary" onClick={() => handleIncrease()}>
                                <AddIcon/>
                            </IconButton >
                        </div>
                    </div>

                </CardActions>
            </Card>
        </div>
    )
}

export default RestaurantMenuItem;
