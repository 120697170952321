import React from 'react';
import {PaneContainer, Pane, CommentUserName, CommentDate, CommentContent} from './styles/styles';
import RatingInfo from "../RatingInfo/RatingInfo";


export default  function RatingPane({author, date, content, rating}) {

    return(<PaneContainer>
        <Pane>
            <CommentUserName>
                {author}
            </CommentUserName>

            <CommentDate>
                 {date}
            </CommentDate>

            <RatingInfo rating={rating} numVotes={-1}/>

            <CommentContent>{content}</CommentContent>


        </Pane>
    </PaneContainer>)
}