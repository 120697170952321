import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {navigate} from "@reach/router";
import Grid from "@material-ui/core/Grid";
import Background from "../../assets/bg.png";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > span': {
            margin: theme.spacing(2),
        },
    },
    restaurant_menu_item__actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    restaurantDescription: {
        whiteSpace: 'pre-line',
        fontSize: '16px',
        color: '#757575',
        lineHeight: '150%',
    },
    buttons: {
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },

        [theme.breakpoints.down('sm')]: {
            '& > *': {
                width: '100%',
            },
        },
        [theme.breakpoints.up('sm')]: {
            '& > *': {
                width: 'unset',
            },
        },
    },
    page: {
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        maxHeight: '240px',
        height: '240px'
    },
    pageWrapper: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pageOverlay: {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.1,
        zIndex: 0,
        position: "absolute",
        maxHeight: '410px'
    },
    pageContainer: {
        zIndex: 1,
        position: "relative",
        marginTop: -80
        /*        marginTop: 186,
                marginBottom: 30*/
    },
    p: {
        fontSize: '16px',
        color: '#757575',
    },
    cardContent: {
        padding: 30,

        '& h1': {
            fontSize: '28px',
            fontWeight: 'bold',
            lineHeight: '150%',
            color: '#757575',
        },
        '& h2': {
            fontSize: '28px',
            fontWeight: 'bold',
            color: '#757575',
            lineHeight: '150%',
        }
    },
}));

function RestaurantHeader({user, restaurant, handleOpenDialog, onClick}) {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.page}>
                <div className={classes.pageWrapper}>
                    <div className={classes.pageOverlay}></div>
                </div>
            </div>
            {/*<Box my={30}/>*/}
            <Container component="main" maxWidth="lg">
                <Grid container className={classes.pageContainer}>
                    <Grid item xs={12} className={classes.pageIntro}>
                        <Card className={classes.card}>
                            {/*<CardMedia
                                        component="img"
                                        alt={restaurant.name}
                                        height="140"
                                        image={restaurant.image_filename}
                                        title={restaurant.name}
                                        onClick={onClick}
                                    />*/}
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h2" component="h1">
                                    Zarządzaj menu
                                </Typography>
                                <Box my={3}>
                                    <Divider variant="fullWidth"/>
                                </Box>
                                <Typography gutterBottom variant="p" component="p" className={classes.p}>
                                    Dane kuchni
                                </Typography>
                                <Typography gutterBottom variant="h2" component="h2">
                                    {restaurant.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p"
                                            className={classes.restaurantDescription}>
                                    {restaurant.description}
                                </Typography>
                                <Box my={3}>
                                    <Divider variant="fullWidth"/>
                                </Box>
                                <Grid container justify="flex-start" className={classes.buttons}>
                                    <Button variant="outlined" color="primary"
                                            onClick={() => navigate(user.restaurant.url)}>Podgląd</Button>
                                    {/*
                                        <Button variant="contained" color="secondary" onClick={() => handleOpenDialog('company')}>
                                            Ewidencja
                                        </Button>
                                    */}
                                    <Button variant="contained" color="default"
                                            onClick={() => handleOpenDialog('openingHours')}>
                                        Godziny otwarcia
                                    </Button>

                                    <Button variant="contained" color="default"
                                            onClick={() => handleOpenDialog('delivery')}>
                                        Dostawa
                                    </Button>

                                    <Button variant="contained" color="default"
                                            onClick={() => handleOpenDialog('restaurant')}>
                                        Zmień informacje o kuchni
                                    </Button>

                                    <Button variant="contained" color="default"
                                            onClick={() => handleOpenDialog('addMenuCollection')}>
                                        Dodaj nową grupę dań
                                    </Button>

                                    <Button variant="contained" color="default"
                                            onClick={() => handleOpenDialog('addMenuItem')}>
                                        Dodaj nowe danie
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default RestaurantHeader;


/*

<Card className={classes.root} onClick={onClick}>
    <CardActionArea>
        <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={restaurant.image_filename}
            title="Contemplative Reptile"
        />
        <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
                {restaurant.name}
            </Typography>
        </CardContent>
    </CardActionArea>
</Card>*/
