import React from "react";
import TextField from "@material-ui/core/TextField";
import getDeliveryDays from "../middlewares/deliveryDays";

import MomentUtils from '@material-ui/pickers/adapter/moment';



import { DateRangePicker,  DateRangeDelimiter, StaticDateRangePicker } from "@material-ui/pickers";
import LocalizationProvider from "@material-ui/pickers/LocalizationProvider";


import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";



export default function BasicDateRangePicker({selectedDate, onDatesRangeChanged, daysOff}) {


    var dr = [];

    if(typeof (selectedDate) === 'undefined' || selectedDate === null || typeof (selectedDate.startDate) === 'undefined' || selectedDate.startDate === null
        || typeof(selectedDate.endDate) === 'undefined' || selectedDate.endDate === null
    ) {
        dr = [null, null];
    } else {
        dr = [selectedDate.startDate, selectedDate.endDate];
    }

    function disableDays(d) {
        return daysOff.includes(new Date(d._d).getDay());
    }

    const [selectedDt, handleDateChange] = React.useState(dr);


    function onDateSelectionChanged(date){
        var dates = [];

        if(date[0] !== null){
            dates[0] = new Date(date[0]._d);
        } else {
            dates[0] = null;
        }

        if(date[1] !== null){
            dates[1] = new Date(date[1]._d);
        } else {
            dates[1] = null;
        }

        handleDateChange(dates);
        onDatesRangeChanged(dates, getDeliveryDays(dates, daysOff));

    }

   /* function getDeliveryDays(datesRange){
        console.log('dr: ', datesRange);

        if(datesRange === null || datesRange[0] === null || datesRange[1] === null ){
            return [];
        }

        const dayMilis = 86400000;

        const dateStart = new Date(datesRange[0]._d).getTime();
        const dateEnd = new Date(datesRange[1]._d).getTime();

        var selectedDays = [];

        for(var i = dateStart; i <= dateEnd; i+=dayMilis){
            var d = new Date(i);
            if(!daysOff.includes(d.getDay())){
            selectedDays.push(d.getTime());
            }
        }



        return selectedDays;
    }*/

    return (



            <LocalizationProvider dateAdapter={MomentUtils}>
            <StaticDateRangePicker
                displayStaticWrapperAs="mobile"
                startText="Data początkowa"
                endText="Data końcowa"
                value={selectedDt}
                showToolbar = {false}
                toolbarTitle='Wybierz terminy dostaw diety pudełkowej'
                disablePast
                shouldDisableDate={date => disableDays(date) }
                onChange={date => onDateSelectionChanged(date)}
                renderInput={(startProps, endProps) => (
                    <>
                        <TextField {...startProps} />
                        <DateRangeDelimiter> to </DateRangeDelimiter>
                        <TextField {...endProps} />
                    </>
                )}
            />
            </LocalizationProvider>


    );
}