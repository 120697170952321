import Typography from "@material-ui/core/Typography";
import React from "react";
import Box from "@material-ui/core/Box";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles((theme) => ({

    header:{
        color: theme.palette.secondary.main,
        font: "normal normal normal 20px/24px Roboto",
        letterSpacing: "0.19px"
    },

    textWithIcon: {
        display: 'flex',
        font: "normal normal normal 16px/24px Roboto",
        letterSpacing: "0.15px",
        marginTop: 16,
        color: theme.palette.secondary.main,
        "& > span": {
            marginLeft: 8
        }
    },

    restaurantDescription: {
        whiteSpace: 'pre-line'
    }
}));

function OrderInformation({restaurant, user}) {
    const classes = useStyles();

    return (
        <Box mb={3}>

            <Typography gutterBottom variant="h5" component="h2" className={classes.header}>
                    Informacje dodatkowe
                </Typography>

                <Typography className={classes.textWithIcon}>
                    <DescriptionOutlinedIcon fontSize="small"/>

                    <Typography component="paragraph" className={classes.restaurantDescription}>
                        {restaurant.description}
                    </Typography>
                </Typography>
            <Box my={1}>
                <Divider variant="fullWidth"/>
            </Box>
        </Box>
    );
}

export default OrderInformation;
