import React from 'react';

export const toNumber = (number, digits = null) => {
    try {
        let tmp = parseFloat(number);

        if (digits) {
            return tmp.toFixed(digits);
        }

        return tmp;
    } catch (error) {
        return 0;
    }
}

export const getLandingName = (name) => {
    try {
        return name.replace('/', '');
    } catch (error) {
        return null;
    }
}

