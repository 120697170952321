import React from "react";
import {logout, login, fetchProfile} from './user'
import {addCart, addCartBoxDiet, modifyCardBoxDietDatesRange, decreaseQuantity, increaseQuantity, removeCart, resetCart} from './cart'
import {useDispatch} from "react-redux";
import {fetchOrder, postOrder, resetOrder, selectOrderDelivery, selectOrderPayment} from "./order";
import {addRestaurantId, fetchRestaurant} from "./restaurant";

export function useLogin() {
    const dispatch = useDispatch();
    return React.useCallback(user => dispatch(login(user)), [dispatch]);
}

export function useFetchProfile() {
    const dispatch = useDispatch();
    return React.useCallback(() => dispatch(fetchProfile()), [dispatch]);
}

export function useLogout() {
    const dispatch = useDispatch();
    return React.useCallback(() => dispatch(logout()), [dispatch]);
}

export function useAdd() {
    const dispatch = useDispatch();
    return React.useCallback( product => dispatch(addCart(product)), [dispatch]);
}

export function useAddBoxDiet() {
    const dispatch = useDispatch();
    return React.useCallback((product, datesRange, deliveryDates) => dispatch(addCartBoxDiet(product, datesRange, deliveryDates)), [dispatch]);
}


export function useModifyCardBoxDietDatesRange(){
    const dispatch = useDispatch();
    return React.useCallback((orderItemId,  datesRange, deliveryDates) => dispatch(modifyCardBoxDietDatesRange(orderItemId, datesRange, deliveryDates)), [dispatch]);
}

export function useIncreaseQuantity() {
    const dispatch = useDispatch();
    return React.useCallback(id => dispatch(increaseQuantity(id)), [dispatch]);
}

export function useDecreaseQuantity() {
    const dispatch = useDispatch();
    return React.useCallback(id => dispatch(decreaseQuantity(id)), [dispatch]);
}

export function useCartReset() {
    const dispatch = useDispatch();
    return React.useCallback(() => dispatch(resetCart()), [dispatch]);
}

export function useRemoveCart() {
    const dispatch = useDispatch();
    return React.useCallback(id => dispatch(removeCart(id)), [dispatch]);
}

export function useSelectOrderPayment() {
    const dispatch = useDispatch();
    return React.useCallback((payment) => dispatch(selectOrderPayment(payment)), [dispatch]);
}

export function useSelectOrderDelivery() {
    const dispatch = useDispatch();
    return React.useCallback((delivery) => dispatch(selectOrderDelivery(delivery)), [dispatch]);
}

export function useResetOrder() {
    const dispatch = useDispatch();
    return React.useCallback(() => dispatch(resetOrder()), [dispatch]);
}

export function useFetchOrder() {
    const dispatch = useDispatch();
    return React.useCallback(() => dispatch(fetchOrder()), [dispatch]);
}

/*export function usePostOrder() {
    const dispatch = useDispatch();
    return React.useCallback((order) => dispatch(postOrder(order)), [dispatch]);
}*/

export function useAddRestaurantId() {
    const dispatch = useDispatch();
    return React.useCallback(id => dispatch(addRestaurantId(id)), [dispatch]);
}

export function useFetchRestaurant() {
    const dispatch = useDispatch();
    return React.useCallback(id => dispatch(fetchRestaurant(id)), [dispatch]);
}