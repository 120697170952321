import React, {useState} from "react";
import {useCart, useCartRestaurantTotal} from "../selector";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import RestaurantOrders from "./RestaurantOrders";
import RestaurantOrdersAmount from "./RestaurantOrdersAmount";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {navigate} from "@reach/router";
import {useAddRestaurantId} from "../actions";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import background from "../assets/minicart/fastfood-24px.png"
import {calculateDeliveryPrice} from "../services/restaurantService";
import Price from "./Price";
import {Tooltip} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    order__title: {
        textAlign: 'center',
        font: 'normal normal bold 28px/42px Roboto',
        color: '#757575',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

    },
    order__subtitle: {
        color: '#757575',
        font: 'normal normal normal 16px/29px Roboto',
        marginTop: '10px'
    },
    order___subtitle: {
        color: '#757575',
        font: 'normal normal normal 18px/29px Roboto',
        marginTop: '10px',
        display: "inline-flex",
        borderColor:"#B7B7B7"
    },
    order_button: {
        width: "100%",
        height: "50px"
    },
    order_box: {
        marginLeft: "auto",
        marginRight: "auto",

        [theme.breakpoints.down('md')]: {
            zIndex: 1001,
            position: 'fixed',
            left: 0,
            bottom: 0,
            right: 0,
        },
    },
    motordelivery: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: '#FA4A0C',

    },
    background: {
        minHeight: 280,
        backgroundRepeat: "no-repeat",
        background: 'transparent',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',

    }
}));

function MiniCart({restaurant, showOrderButton = false, delivery = null}) {

    const classes = useStyles();
    const cartProducts = useCart();
    const addRestaurantId = useAddRestaurantId();
    const amount = useCartRestaurantTotal(restaurant.id);

    const deliveryPrice = calculateDeliveryPrice(restaurant, amount)
    const total = amount + deliveryPrice;

    console.log('restaurant.id', restaurant.id);
    console.log('delivery', delivery);

    const [user, setUser] = useState(null);

    function order() {
        console.log('restaurant.id', restaurant.id);
        addRestaurantId(restaurant.id);
        navigate('/order');
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography align="center" className={classes.order__title}>
                    Twoje zamówienie
                </Typography>

                {delivery && delivery === 'house' && (
                    <Box>
                        <Box display="flex" justifyContent="center" borderBottom={"1px solid #E9E9E9"} padding={"15px"} paddingTop={"0"}>
                            <Tooltip title="Koszty dostawy" aria-label="add">
                                <Typography variant="body2" component="p" align="center" className={classes.order___subtitle}>
                                    <MotorcycleIcon color="primary"/>
                                    <b>{calculateDeliveryPrice(restaurant, amount)} zł</b>
                                </Typography>
                            </Tooltip>
                        </Box>

                        {restaurant.surcharge_price > 0 && amount < 25 && (
                            <Box p={2}>
                                <Typography variant="body2" component="p" align="center" className={classes.order__subtitle}>
                                    dopłata <b><Price value={restaurant.surcharge_price}/></b> do zamówień <b>poniżej 25 zł</b>
                                </Typography>
                            </Box>
                        )}

                        {restaurant.surcharge_price === 0 && amount < 25 && (
                            <Box p={2}>
                                <Typography variant="body2" component="p" align="center" className={classes.order__subtitle}>
                                    <b>Brak dopłaty do zamówienia, poniżej 25 zł</b>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                {amount > 0 && (
                    <div>
                        <Box>
                        <RestaurantOrders restaurant={restaurant}/>
                        </Box>
                        <RestaurantOrdersAmount restaurant={restaurant} delivery={delivery}/>
                    </div>
                )}

                {!amount && (
                    <Box textAlign={"center"}>
                        <Box className={classes.background}/>
                        Jeszcze nic nie zamówiono...
                    </Box>
                )}

            </CardContent>

            {amount > 0 && showOrderButton && cartProducts && (
                <Box pt={1} pb={3} pl={4} pr={4} className={classes.order_box}>
                    {total >= restaurant.minimum_price ? (
                        <Link
                            onClick={order}>
                            <Button variant="contained" size="large" color="primary" className={classes.order_button}>
                                Zamów za&nbsp;<Price value={total}/>
                            </Button>
                        </Link>
                    ) : (
                        <Typography component={"p"}>
                            Minimalna wartośc zamówienia to <Price value={restaurant.minimum_price}/>
                        </Typography>
                    )}
                </Box>
            )}
        </Card>
    )
}

export default MiniCart;
