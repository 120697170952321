import {useUser} from "../../selector";
import {Redirect} from "@reach/router";
import Typography from "@material-ui/core/Typography";
import React from "react";
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    header: {
        color: theme.palette.secondary.main,
        font: "normal normal normal 20px/24px Roboto",
        letterSpacing: "0.19px"
    },
    textWithIcon: {
        display: 'flex',
        font: "normal normal normal 16px/24px Roboto",
        letterSpacing: "0.15px",
        marginTop: 16,
        color: theme.palette.secondary.main,
        "& > span": {
            marginLeft: 8
        }
    }
}));

function OrderContact({user}) {
    const classes = useStyles();

    return (
        <Box mb={3}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.header}>
                Dane kontaktowe
            </Typography>
            <Typography className={classes.textWithIcon}>
                <PhoneRoundedIcon fontSize="small"/>

                <Typography component="span">
                    {user.phone}
                </Typography>
            </Typography>
            <Box my={1}>
                <Divider variant="fullWidth"/>
            </Box>
        </Box>
    );
}

export default OrderContact;
