
import styled from 'styled-components';
import Rating from '@material-ui/lab/Rating/Rating';

export const RatingContainer = styled.div`
    display: flex;
`;

export const RatingText = styled.span`
    font-size: 0.85rem;
    margin-right: 0.5rem;
    line-height: 1.1rem;
`;

export const RatingStars = styled(Rating)`
    
        font-size: 1rem;
        margin-right: 0.5rem;
    
`;