import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import {clearSnackbar} from "../actions/ui";
import Alert from "@material-ui/lab/Alert";

export default function SuccessSnackbar() {
    const dispatch = useDispatch();

    const {successSnackbarMessage, successSnackbarOpen, alertType} = useSelector(
        state => state.ui
    );

    function handleClose() {
        dispatch(clearSnackbar());
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            open={successSnackbarOpen}
            autoHideDuration={alertType === 'error' ? 4000 : 800}
            onClose={handleClose}
            aria-describedby="client-snackbar"
        >
            <Alert onClose={handleClose} severity={alertType === 'error' ? 'success' : 'info'}>
                {successSnackbarMessage}
            </Alert>
        </Snackbar>
    );
}
