import React, {useState} from "react";
import RestaurantList from "../components/RestaurantList";
import {useUser} from "../selector";
import HeaderBar from "../components/HeaderBar";
import RestaurantCategories from "../components/RestaurantCategories";
import ajaxClient from "../services/ajaxClient";
import Container from "@material-ui/core/Container";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getQuery, getQueryLink, parseQuery} from "../services/localizationService";
import SearchBar from "../components/SearchBar";
import Footer from "./Footer";
import {FormControlLabel, Switch} from "@material-ui/core";
import Background from '../assets/bg.png';
import MapContainer from "../components/Maps/MapContainer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "../components/Loader"; // Import using relative path

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        marginBottom: '15px'
    },
    mapContainer: {
        /*backgroundColor: 'red',*/
        position: 'relative',
        '& $items': {
            /*backgroundColor: 'green',*/
            padding: 16,
            [theme.breakpoints.up('lg')]: {
                maxWidth: '808px'
            },
        },
        '& $map': {
            [theme.breakpoints.down('lg')]: {
                top: 0,
                position: 'fixed',
                width: '100%',
                height: '100%'
            },
            [theme.breakpoints.up('lg')]: {
                width: 'calc(100% - 840px)',
                inset: '0px 0px 0px',
                position: 'absolute',
                top: 0,
                left: 'auto !important',
            },
            /*backgroundColor: 'grey',*/
        },

        '& $mapBox': {
            [theme.breakpoints.up('lg')]: {
                height: '100vh',
                paddingtop: '80px',
                marginTop: '-80px',
                position: 'sticky',
                top: '0px',
            },
            /*backgroundColor: 'yellow',*/
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 100%",
        }
    },
    filters: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    },

    mapSwitchFilter: {
        [theme.breakpoints.down('md')]: {
            position: 'fixed',
            zIndex: '10001',
            background: 'white',
            width: '100%',
            left: '0px',
            padding: '15px',
            top: '64px',
        },
    },

    map: {
        backgroundColor: 'gray',
        width: '100%',
        height: '100%',
    },

    items: {},
    mapBox: {}
}));

function Restaurants(props) {
    const classes = useStyles();

    const query = props.query || '';

    console.log('[query]', query);
    console.log('[parseQuery]', parseQuery(query));

    const user = useUser();

    const [restaurants, setRestaurants] = useState(null);
    const [category, setCategory] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [showMap, setShowMap] = useState(parseQuery(query).showMap);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        if (formValues) {

            async function fetchRestaurants() {
                const response = await ajaxClient().get(`restaurants?` + getQuery(formValues));
                setRestaurants(response.data.data);
            }

            async function fetchCategory() {
                if (formValues.category) {
                    const response = await ajaxClient().get(`categories?` + getQuery(formValues) + "&first");
                    setCategory(response.data.data);
                } else {
                    setCategory(null);
                }
            }

            fetchRestaurants();
            fetchCategory();
        }
    }, [formValues]);

    function handleClick(event) {
        event.preventDefault();
    }

    function handleMap(event) {
        setShowMap(event.target.checked);
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/restaurants" titleBar=""/>
            {(!showMap || 1==2) && (
                <SearchBar/>
            )}

            <Container disableGutters={!!showMap} maxWidth={showMap ? false : "lg"} className={showMap ? classes.mapContainer : classes.container}>
                <Box className={classes.items}>
                    <Box pt={2} pb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" onClick={handleClick}>
                                Strona główna
                            </Link>

                            <Link
                                color="textPrimary"
                                href="/restaurants"
                                to="/restaurants"
                                /*onClick={() => navigate("/restaurants")}*/
                            >
                                Kucharze
                            </Link>

                            {category && category.name && (
                                <Link
                                    color="textPrimary"
                                    href={getQueryLink(formValues, {'category': category.name})}
                                    to={getQueryLink(formValues, {'category': category.name})}
                                    /*onClick={() => navigate(`/${country}/${city}/restaurants/category/${category.slug}`)}*/
                                    aria-current="page"
                                >
                                    {category.name}
                                </Link>)
                            }

                        </Breadcrumbs>

                        <Box mt={2}>
                            <Typography component={"h1"} variant={"h4"}>Najlepsi kucharze w Twoim pobliżu</Typography>
                        </Box>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"} className={classes.filters}>
                        <RestaurantCategories query={query}/>
                        <Box py={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showMap}
                                        onChange={handleMap}
                                        name="map"
                                        color="primary"
                                    />
                                }
                                label="Mapa"
                            />
                        </Box>
                    </Box>

                    {!restaurants && (
                        <Loader>Trwa ładowanie kucharzy...</Loader>
                    )}

                    {restaurants && (
                        <RestaurantList category={category} restaurants={restaurants}/>
                    )}
                </Box>
                {showMap && (
                    <Box className={classes.map}>
                        <Box py={2} className={classes.mapSwitchFilter}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showMap}
                                        onChange={handleMap}
                                        name="map"
                                        color="primary"
                                    />
                                }
                                label="Mapa"
                            />
                        </Box>
                        <Box className={classes.mapBox}>
                            <MapContainer restaurants={restaurants}/>
                        </Box>
                    </Box>
                )}
            </Container>
            <Footer fixed={!showMap}/>
        </div>
    );
}

export default Restaurants;
