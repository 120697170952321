import {navigate, Redirect} from "@reach/router";
import {useCart, useCartRestaurantTotal, useRestaurant, useRestaurantId} from "../selector";
import OrderPickUp from "../components/OrderPickUp";
import HeaderBar from "../components/HeaderBar";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import React, {useState} from "react";
import {useFetchRestaurant} from "../actions";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OrderContact from "../components/Controls/OrderContact";
import OrderInformation from "../components/Controls/OrderInformation";
import {showSuccessSnackbar} from "../actions/ui";
import {useDispatch} from "react-redux";
import CardActionArea from "@material-ui/core/CardActionArea";
import MiniCart from "../components/MiniCart";
import Footer from "./Footer";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {postOrder, processOrder} from "../services/orderService";
import {hasError} from "../services/validators";
import GeneralError from "../components/GeneralError";
import Button from "@material-ui/core/Button";
import {getProfile} from "../services/userService";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import UserCompanyDialog from "../components/Profile/Dialogs/UserCompanyDialog";
import HeaderOverlay from "../components/HeaderOverlay";
import Auth from "./Profile/Auth";
import Price from "../components/Price";
import {calculateDeliveryPrice} from "../services/restaurantService";
import FormHelperText from "@material-ui/core/FormHelperText";
import BasicDateRangePicker from "../components/BasicDateRangePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },

    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    breadcrumbLink: {
        fontSite: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.15px',
        color: '#757575'
    },
    breadcrumbActiveLink: {
        fontSite: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.15px',
        fontWeight: 'bold',
        color: '#757575'
    },
    restaurant__name: {
        font: 'normal normal normal 34px/41px Roboto',
        letterSpacing: '1.13px',
        color: '#757575',
        marginTop: '30px'
    },
    restaurant__description: {
        font: 'normal normal normal 16px/18px Roboto',
        letterSpacing: '0.53px',
        color: '#757575',
        marginTop: '15px',
        whiteSpace: 'pre-line'
    },

    restaurant__container: {
        zIndex: 2,
        position: 'relative',
        marginTop: -80,
        marginBottom: 30
    },
    restaurant__title_section: {
        padding: theme.spacing(4),

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    },

    return_section: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '21px',

        '& > span': {
            fontSize: 24,
            fontWeight: 'bold',

            [theme.breakpoints.down('md')]: {
                fontSize: 14,
            },
        },
        color: theme.palette.secondary.main,
    },

    restaurant_name: {
        fontSize: 34,
        fontFamily: 'Roboto',
        letterSpacing: '1.13px',
        color: '#FA4A0C',
        opacity: '1',
        paddingBottom: 30,

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            paddingBottom: 15,
        },
    },

    formControl: {
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        color: theme.palette.secondary.main,
        font: "normal normal normal 20px/24px Roboto",
        letterSpacing: "0.19px",

    },
    menuitem: {
        color: theme.palette.secondary.main,
    }
}));


function Order() {
    console.log('start');
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState({
        'userCompany': {
            open: false,
            data: null
        }
    });

    const [order, setOrder] = React.useState({
        'delivery': null,
        'payment': null,
        'other_hour': false,
        'hours': null
    });
    const [errors, setErrors] = React.useState(null);

    const cart = useCart();
    const [user, setUser] = useState(null);
    const restaurant = useRestaurant();
    const restaurantId = useRestaurantId();
    const fetchRestaurant = useFetchRestaurant();
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const amount = useCartRestaurantTotal(restaurantId);

    const deliveryPrice = calculateDeliveryPrice(restaurant, amount)
    const total = amount + deliveryPrice;

    const checkRequirements = () => {
        console.log('check reqire', user);
        if (user && user.id) {
            if (user.delivery && !user.delivery[0]) {
                if (user && user.delivery && !user.delivery[0]) {
                    dispatch(showSuccessSnackbar("Uzupełnij dane dostawy"));
                    navigate('/profile/delivery?firstTime=1&from=order');
                } else if (!user.delivery || user.delivery[0].city == '' || user.delivery[0].country == '') {
                    dispatch(showSuccessSnackbar("Uzupełnij dane dostawy"));
                    navigate('/profile/delivery?firstTime=0&from=order');
                }
            }

            if (user && (!user.phone || user.phone == '')) {
                dispatch(showSuccessSnackbar("Podaj dane kontaktowe"));
                navigate('/profile/contact?firstTime=1&from=order');
            }
        } else {
            //navigate('/login?from=order');
        }
    }

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser', response);
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        checkRequirements();
    }, [user]);

    React.useEffect(() => {
        reloadUser();
        fetchRestaurant(restaurantId);
    }, []);

    React.useEffect(() => {
        if (errors) {
            if (hasError('items', errors)) {
                dispatch(showSuccessSnackbar(errors.items[0]));
            } else {
                dispatch(showSuccessSnackbar("Wystąpił błąd przy składaniu zamówienia"));
            }
        }
    }, [errors]);

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });
    }

    function handleCloseDialog(name, result) {
        reloadUser();

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    if (!restaurantId) {
        console.log('redirect');
        return <Redirect to="/login" noThrow/>;
    }

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log('[ORDER] change', {
            [name]: value
        });

        setOrder({
            ...order,
            [name]: value
        });

        if (name == 'invoice' && value) {
            handleOpenDialog('userCompany');
        }
    }

    function sendOrder() {
        setIsFormProcessed(true);

        const data = {
            invoice: order.invoice,
            payment: order.payment,
            delivery: order.delivery,
            other_hour: order.other_hour,
            hours: order.hours,
            restaurant_id: restaurantId,
            items: Object.values(cart).map(value => {
                return {
                    menu_item_id: value.id,
                    quantity: value.quantity,
                    dates_range: typeof (value.datesRange) !== 'undefined' && value.datesRange !== null ?
                        Object.assign(
                        {
                            start_date: value.datesRange.startDate,
                            end_date: value.datesRange.endDate
                        }) : null,
                    delivery_dates: typeof(value.deliveryDates) !== 'undefined' && value.deliveryDates !== null ?
                        Object.assign(value.deliveryDates) : null
                };
            })
        };

        console.log('[ORDER] data', data);

        postOrder(data).then(response => {
            setIsFormProcessed(false);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                //dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                //cartReset();
                setOrder(response.data);
                dispatch(showSuccessSnackbar("Zamówienie zostało żłożone. Otrzymasz dostawę jak tylko Twoje pyszności będą gotowe."));
                processOrder(response.data);
            }
        })
        //cartReset();
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/order" titleBar="Order"/>
            <Auth>
                    <Box>
                        <UserCompanyDialog open={openDialog.userCompany.open} data={openDialog.userCompany.data}
                                           onClose={() => handleCloseDialog('userCompany')}/>

                        <HeaderOverlay></HeaderOverlay>

                        {restaurant && user && (
                            <Container maxWidth="lg" className={classes.restaurant__container}>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    <Grid item md={8} sm={8} xs={12}>
                                        <Card className={classes.card}>
                                            <CardActionArea>
                                                <CardContent className={classes.restaurant__title_section}>

                                                    <HeaderBreadcrumb name={"Złóż zamówienie"} url={"/order"}/>

                                                    <Link
                                                        href={`${restaurant.url}`}
                                                        color="secondary">
                                                        <Typography className={classes.return_section}>
                                                            <ArrowBackIcon/>
                                                            <Typography component="span">Podsumowanie zamówienia</Typography>
                                                        </Typography>
                                                    </Link>

                                                    <Box mt={3} pl={2} pr={2}>

                                                        <GeneralError errors={errors}/>

                                                        <Typography className={classes.restaurant_name}>
                                                            {restaurant.name}
                                                        </Typography>

                                                        <Box mb={3}>
                                                            <Typography gutterBottom variant="h5" component="h2"
                                                                        className={classes.header}>
                                                                Dane dostawy
                                                            </Typography>

                                                            <FormControl variant="outlined" className={classes.formControl}>
                                                                <Select
                                                                    name={"delivery"}
                                                                    labelId="delivery-select-label"
                                                                    id="delivery-select"
                                                                    value={order.delivery}
                                                                    onChange={handleChange}
                                                                    variant="outlined"

                                                                    error={hasError('delivery', errors)}
                                                                    helperText={
                                                                        hasError('delivery', errors) ? errors.delivery[0] : null
                                                                    }
                                                                >
                                                                    <MenuItem value={''}>Wybierz</MenuItem>

                                                                    {restaurant.has_delivery_house && (
                                                                        <MenuItem value={'house'}>Dostawa do domu</MenuItem>
                                                                    )}

                                                                    {restaurant.has_delivery_pickup && (
                                                                        <MenuItem value={'pickup'}>Odbiór osobisty</MenuItem>
                                                                    )}

                                                                    {restaurant.has_delivery_restaurant && (
                                                                        <MenuItem value={'restaurant'}>Zjem u Kucharza</MenuItem>
                                                                    )}
                                                                </Select>
                                                                {hasError('delivery', errors) && (
                                                                    <FormHelperText>Pole wymagane</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Box>
                                                        <Box mb={3}>
                                                            <Typography gutterBottom variant="h5" component="h2"
                                                                        className={classes.header}>
                                                                Informacje o płatności
                                                            </Typography>

                                                            <FormControl variant="outlined" className={classes.formControl}>
                                                                <Select
                                                                    name={"payment"}
                                                                    labelId="payment-select-label"
                                                                    id="payment-select"
                                                                    value={order.payment}
                                                                    onChange={handleChange}
                                                                    variant="outlined"

                                                                    error={hasError('payment', errors)}
                                                                    helperText={
                                                                        hasError('payment', errors) ? errors.payment[0] : null
                                                                    }
                                                                >
                                                                    <MenuItem value={''}>Wybierz</MenuItem>
                                                                    <MenuItem value={'cash'} className={classes.menuitem}>Zapłać
                                                                        gotówką</MenuItem>
                                                                    <MenuItem value={'card'} className={classes.menuitem}>Płatność
                                                                        elektroniczna</MenuItem>
                                                                </Select>
                                                                {hasError('payment', errors) && (
                                                                    <FormHelperText>Pole wymagane</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Box>

                                                        {/*<Box mb={3}>

                                                            <Typography gutterBottom variant="h5" component="h2"
                                                                        className={classes.header}>
                                                                Godzina dostawy
                                                            </Typography>

                                                            <FormControl variant="outlined" className={classes.formControl}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={order.other_hour ?? 'checked'}
                                                                                       onChange={handleChange} name="other_hour"/>}
                                                                    label={"Inna godzina dostawy"}
                                                                />
                                                            </FormControl>
                                                            {order.other_hour && (
                                                                <FormControl variant="outlined" className={classes.formControl}>
                                                                    <Select
                                                                        name={"hours"}
                                                                        labelId="hours-select-label"
                                                                        id="hours-select"
                                                                        value={order.hours}
                                                                        onChange={handleChange}
                                                                        variant="outlined"
                                                                        placeholder={"Wybierz przybliżoną godzinę dostawy"}

                                                                        error={hasError('hours', errors)}
                                                                        helperText={
                                                                            hasError('hours', errors) ? errors.hours[0] : null
                                                                        }
                                                                    >
                                                                        <MenuItem value={null}>Wybierz</MenuItem>

                                                                        getHours(dateFrom



                                                                        <MenuItem value={'10:15'} className={classes.menuitem}>10:15</MenuItem>
                                                                        <MenuItem value={'10:30'} className={classes.menuitem}>10:30</MenuItem>

                                                                        <MenuItem value={'10:45'} className={classes.menuitem}>10:45</MenuItem>
                                                                        <MenuItem value={'11:00'} className={classes.menuitem}>11:00</MenuItem>
                                                                        <MenuItem value={'11:15'} className={classes.menuitem}>11:15</MenuItem>

                                                                    </Select>
                                                                    {hasError('hours', errors) && (
                                                                        <FormHelperText>Pole wymagane</FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            )}
                                                        </Box>*/}

                                                        {restaurant.is_company ? (
                                                            <Box mb={3}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={order.invoice}
                                                                                       onChange={handleChange} name="invoice"/>}
                                                                    label="Chcę otrzymać fakturę VAT"
                                                                />
                                                            </Box>
                                                        ) : ''}

                                                        <OrderPickUp user={user}/>
                                                        <OrderContact user={user}/>
                                                        <OrderInformation restaurant={restaurant} user={user}/>
                                                    </Box>

                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item md={4} sm={4} xs={12}>
                                        <MiniCart restaurant={restaurant} showOrderButton={false} delivery={order.delivery}/>

                                    </Grid>
                                </Grid>
                                <Box p={4} className={classes.restaurant__order}>
                                    <Typography gutterBottom variant="h4" align="center">
                                        <Button
                                            onClick={sendOrder}
                                            variant={"contained"}
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            disabled={isFormProcessed || total < restaurant.minimum_price}
                                        >
                                            {!isFormProcessed ? <Box>Finalizuj zamówienie</Box> : <Box>Wysyłanie...</Box>}
                                        </Button>

                                        {total < restaurant.minimum_price && (
                                            <Typography component={"p"} className={classes.muted} color={'secondary'}>
                                                Minimalna wartośc zamówienia to <Price value={restaurant.minimum_price}></Price>
                                            </Typography>
                                        )}
                                    </Typography>
                                </Box>
                            </Container>
                        )}
                    </Box>
            </Auth>

            <Footer/>
        </div>
    );
}

export default Order;
