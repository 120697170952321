import {useCart} from "../selector";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    useAdd,
    useAddBoxDiet,
    useDecreaseQuantity,
    useIncreaseQuantity,
    useModifyCardBoxDietDatesRange,
    useRemoveCart
} from "../actions";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from "@material-ui/core/IconButton";
import Price from "./Price";
import Box from "@material-ui/core/Box";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../actions/ui";
import BoxDietDates from "./BoxDiet/BoxDietDates";
import getDaysOff from '../middlewares/restaurantDays';

import priceCalc from '../middlewares/priceCalc';

const useStyles = makeStyles((theme) => ({
    quantity: {
        font: 'normal normal bold 15px/21px Roboto',
        letterSpacing: '0.18px',
        color: '#757575',
        width: "50px"
    },
    name: {
        textAlign: 'left',
        font: 'normal normal normal 15px/21px Roboto',
        letterSpacing: '0.14px',
        color: '#757575',
        flex: 1,
        '& > span': {
            fontWeight: '300',
            display: 'block',
            fontSize: 12
        }
    },
    price: {
        textAlign: 'right',
        font: 'normal normal bold 15px/21px Roboto',
        letterSpacing: '0.18px',
        color: '#757575'
    },
    actions: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "10px",
        borderBottom: "1px solid #B7B7B7"
    },
    box: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10
    }

}));

function RestaurantOrders({restaurant}) {
    console.log('restaurant orders: ', restaurant);

    const classes = useStyles();
    const cartProducts = useCart();
    const dispatch = useDispatch();

    const removeCart = useRemoveCart();
    const Add = useAdd();
    const AddBoxDiet = useAddBoxDiet();
    const ModifyCardBoxDietDatesRange = useModifyCardBoxDietDatesRange();
    const increaseQuantity = useIncreaseQuantity();
    const decreaseQuantity = useDecreaseQuantity();

    function handleIncrease(menuItemId) {
        if (menuItemId === null) {
            // nic do zrobienia - id null
        } else {
            dispatch(showSuccessSnackbar("Dodano 1 sztukę produktu", "info"));
            increaseQuantity(menuItemId);
        }
    }

    function handleDecrease(menuItemId) {
        if (menuItemId !== null) {
            dispatch(showSuccessSnackbar("Zmniejszono 1 sztukę produktu", "info"));
            decreaseQuantity(menuItemId);
        }
    }

    /*
    function handleDecrease(menuItem) {
        if (menuItem !== null) {
            if (menuItem.quantity === 1) {
                removeCart(menuItem.id);
            } else {
                dispatch(showSuccessSnackbar("Zmniejszono 1 sztukę produktu"));
                decreaseQuantity(menuItem.id);
            }
        }
    } */

    function validateDatesRange(dateStart, dateEnd){
        return dateStart instanceof Date && dateEnd instanceof Date;
    }

    function handleDatesChange(orderItemId, dateStart, dateEnd, deliveryDates) {
        if(typeof (orderItemId) === 'undefined' || orderItemId === null){
            console.log('Null product!');
            return;
        }

        if(!validateDatesRange(dateStart, dateEnd)){
            dispatch(showSuccessSnackbar('Błędny zakres dat dostaw diety pudełkowej.'));
        } else {
            ModifyCardBoxDietDatesRange(orderItemId,
                {
                    dateStart: dateStart,
                    dateEnd: dateEnd
                },
                deliveryDates);
            dispatch(showSuccessSnackbar("Zmodyfikowano daty dostaw diety pudełkowej."));
        }
    }

    return (
        <Box>
            {cartProducts ? (
                <Box css={{maxHeight: "50vh", overflowY: "auto"}}>
                    {Object.entries(cartProducts).map((v) => {
                        let menuItem = v[1];
                        let itemId = v[0];

                        console.log('cart item id: ', itemId);

                        if (restaurant.id === menuItem.restaurant_id) {

                            if(typeof(menuItem.datesRange) !== 'undefined' && menuItem.datesRange !== null){

                            }

                            function onDatesRangeSelected(dateStart, dateEnd, deliveryDates){
                                //console.log('minicart dates: ', dr);

                                menuItem.datesRange.startDate = dateStart;
                                menuItem.datesRange.endDate = dateEnd;
                                menuItem.deliveryDates = deliveryDates;
                                handleDatesChange(itemId, dateStart, dateEnd, deliveryDates);
                                console.log('minicart dates updated: ', menuItem.datesRange);
                            }

                            return (
                                <Box key={itemId}>
                                    <Box className={classes.box}>
                                        <Typography className={classes.quantity}>{menuItem.quantity}x</Typography>
                                        <Typography className={classes.name}>
                                            {menuItem.name} ({menuItem.restaurant_id} vs {restaurant.id})
                                            <Typography component="span">{menuItem.description}</Typography>
                                        </Typography>
                                        <Typography className={classes.price}><Price value={priceCalc(menuItem.price,
                                            menuItem.quantity, typeof (menuItem.deliveryDates) !== 'undefined' && menuItem.deliveryDates.length >= 1 ? menuItem.deliveryDates.length : 1 )}/></Typography>
                                    </Box>

                                    {typeof(menuItem.datesRange) !== 'undefined' && menuItem.datesRange !== null &&
                                    <Box className={classes.box}>

                                        <BoxDietDates
                                            datesRangeSelected={
                                                {
                                                    dateStart: menuItem.datesRange.startDate,
                                                    dateEnd: menuItem.datesRange.endDate
                                                }
                                            }
                                            onDatesRangeSelected={onDatesRangeSelected}
                                            daysOff={getDaysOff(restaurant.opening_hours)}
                                            unitPrice={menuItem.price}
                                        />

                                    </Box>
                                    }

                                    <Box className={classes.actions}>
                                        <IconButton aria-label="Zmniejsz" color="primary" onClick={() => handleDecrease(itemId)}>
                                            <RemoveIcon/>
                                        </IconButton >

                                        <IconButton aria-label="Zwiększ" color="primary" onClick={() => handleIncrease(itemId)}>
                                            <AddIcon/>
                                        </IconButton >
                                    </Box>
                                </Box>
                            )
                        }
                        /* return <RestaurantOrderMenuItem menuItem={menuItem} />;*/
                    })}
                </Box>
            ) : (
                <Typography variant="body2" color="textSecondary" component="h1">
                    PUSTE
                </Typography>
            )}
        </Box>
    );
}

export default RestaurantOrders;
