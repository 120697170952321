import React from "react";
import Restaurant from "../components/Restaurant";
import Grid from "@material-ui/core/Grid";
import Loader from "./Loader";

function RestaurantList({restaurants, showMap}) {
    return (
            <Grid
                container
                spacing={4}
                direction="row"
                alignItems="flex-start"
            >
                {restaurants && restaurants.map(restaurant => {
                    return (
                        <Grid item md={4} sm={6} xs={12} key={restaurant.id}>
                            <Restaurant
                                restaurant={restaurant}
                                key={"restaurant" + restaurant.id}
                            />
                        </Grid>
                    );
                })}
            </Grid>
    );
}

export default RestaurantList;
