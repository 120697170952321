import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderBar from "../../components/HeaderBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Typography} from "@material-ui/core";
import {getProfile} from "../../services/userService";
import Grid from "@material-ui/core/Grid";
import RestaurantMenuItem from "../../components/Profile/RestaurantMenuItem";
import Button from "@material-ui/core/Button";
import AddMenuItemDialog from "../../components/Profile/Dialogs/AddMenuItemDialog";
import RestaurantDialog from "../../components/Profile/Dialogs/RestaurantDialog";
import EditMenuItemDialog from "../../components/Profile/Dialogs/EditMenuItemDialog";
import RestaurantHeader from "../../components/Profile/RestaurantHeader";
import AddMenuCollectionDialog from "../../components/Profile/Dialogs/AddMenuCollectionDialog";
import EditMenuCollectionDialog from "../../components/Profile/Dialogs/EditMenuCollectionDialog";
import {deleteMenuCollection, deleteMenuItem} from "../../services/restaurantService";
import {showSuccessSnackbar} from "../../actions/ui";
import {useDispatch} from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import {navigate} from "@reach/router";
import Auth from "./Auth";
import RemoveIcon from "@material-ui/icons/Remove";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import DeliveryDialog from "../../components/Profile/Dialogs/DeliveryDialog";
import CompanyDialog from "../../components/Profile/Dialogs/CompanyDialog";
import OpeningHours from "../../components/Profile/Dialogs/OpeningHours";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    collectionTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '150%',
        color: '#757575',
        marginRight:'30px',

    },


    /*    ,
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        marginTop: theme.spacing(2)
    },*/
}));

function Menu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [restaurants, setRestaurants] = useState([]);
    const [categories, setCategories] = useState([]);
    const [user, setUser] = useState(null);

    const [openDialog, setOpenDialog] = useState({
        'restaurant': {
            open: false,
            data: null
        },
        'delivery': {
            open: false,
            data: null
        },
        'company': {
            open: false,
            data: null
        },
        'addMenuItem': {
            open: false,
            data: null
        },
        'editMenuItem': {
            open: false,
            data: null
        },
        'addMenuCollection': {
            open: false,
            data: null
        },
        'editMenuCollection': {
            open: false,
            data: null
        },
        'deleteMenuCollection': {
            open: false,
            data: null
        },
        'deleteMenuItem': {
            open: false,
            data: null
        },
        'openingHours' : {
            open: false,
            data: null
        }
    });

    React.useEffect(() => {
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
            var user = response.data;

            if (response.data && !response.data.restaurant) {
                console.log('no restaurant');
                handleOpenDialog('restaurant');

                //navigate("/profile/menu/empty");
                //return <Redirect to="/profile/menu/empty" noThrow/>;
            }
        })
    }

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleCloseDialog(name, result) {
        console.log('handleCloseDialog', name);

        reloadUser();

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    function handleDeleteMenuItem(menuItem) {
        /*dispatch(showSuccessSnackbar("Element został usunięty."));
        reloadUser();
        return;*/
        deleteMenuItem(menuItem).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                dispatch(showSuccessSnackbar(response.data.error.delete));
                reloadUser();
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                reloadUser();
            }
        });
    }

    function handleDeleteMenuCollection(collection) {
        deleteMenuCollection(collection).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                dispatch(showSuccessSnackbar(response.data.error.delete));
                reloadUser();
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                reloadUser();
            }
        });
        console.log('delete collection', collection);
    }

    return (
        <div>
            <HeaderBar routePath="/profile/menu" titleBar="Zarządzaj menu"/>
            <Auth>
                <RestaurantDialog open={openDialog.restaurant.open} data={openDialog.restaurant.data} onClose={() => handleCloseDialog('restaurant')}/>
                <DeliveryDialog open={openDialog.delivery.open} data={openDialog.delivery.data} onClose={() => handleCloseDialog('delivery')}/>
                <CompanyDialog open={openDialog.company.open} data={openDialog.company.data} onClose={() => handleCloseDialog('company')}/>
                <OpeningHours open={openDialog.openingHours.open} data={openDialog.openingHours.data} onClose={() => handleCloseDialog('openingHours')}/>

                <AddMenuItemDialog open={openDialog.addMenuItem.open} data={openDialog.addMenuItem.data} onClose={() => handleCloseDialog('addMenuItem')}/>
                <EditMenuItemDialog open={openDialog.editMenuItem.open} data={openDialog.editMenuItem.data} onClose={() => handleCloseDialog('editMenuItem')}/>

                <AddMenuCollectionDialog open={openDialog.addMenuCollection.open} data={openDialog.addMenuCollection.data} onClose={() => handleCloseDialog('addMenuCollection')}/>
                <EditMenuCollectionDialog open={openDialog.editMenuCollection.open} data={openDialog.editMenuCollection.data} onClose={() => handleCloseDialog('editMenuCollection')}/>

                <ConfirmDialog
                    title="Usuń grupę dań"
                    open={openDialog.deleteMenuCollection.open}
                    data={openDialog.deleteMenuCollection.data}
                    setOpen={(open) => handleCloseDialog('deleteMenuCollection', open)}
                    onConfirm={handleDeleteMenuCollection}
                >
                    Czy na pewno chcesz usunąć wybraną grupę dań?
                </ConfirmDialog>

                <ConfirmDialog
                    title="Usuń pozycję w menu"
                    open={openDialog.deleteMenuItem.open}
                    data={openDialog.deleteMenuItem.data}
                    setOpen={(open) => handleCloseDialog('deleteMenuItem', open)}
                    onConfirm={handleDeleteMenuItem}
                >
                    Czy na pewno usunąć podaną pozycję z Twojego menu?
                </ConfirmDialog>

                {user && user.restaurant && (
                    <RestaurantHeader user={user} restaurant={user.restaurant} handleOpenDialog={handleOpenDialog} onClick={() => handleOpenDialog('restaurant')}/>
                )}

                <CssBaseline/>
                <Container component="main" >
                    <div className={classes.paper}>
                    {user && user.restaurant && (
                        <div>
                            {user && user.collections && user.collections.map(collection => {
                                return (
                                    <Box pt={2} pb={2} mt={3} key={"collection" + collection.id}>
                                        {/*{user.restaurant.menu_items && user.restaurant.menu_items.some(menuItem => menuItem.collection_id === collection.id) && (
                                                <Grid row md={12} sm={12} xs={12} key={collection.id}>
                                                    <Typography gutterBottom variant="h5" component="h2">{collection.name}</Typography>
                                                </Grid>
                                            )
                                        }*/}

                                        <Grid>
                                            <Typography gutterBottom variant="h5" component="h1">
                                                <span className={classes.collectionTitle}>
                                                    {collection.name}
                                                </span>
                                                <IconButton color="default" aria-label="Edytuj grupę dań" onClick={() => handleOpenDialog('editMenuCollection', collection)}>
                                                    <AddIcon/>
                                                </IconButton>

                                                <IconButton color="default" aria-label="Usuń grupę dań" onClick={() => handleOpenDialog('deleteMenuCollection', collection)} >
                                                    <RemoveIcon/>
                                                </IconButton>
                                            </Typography>

                                            <Button variant="contained" color="primary" onClick={() => handleOpenDialog('addMenuItem', {collection: collection})} color="default">
                                                Dodaj do tej grupy dań
                                            </Button>

                                            <IconButton color="default" aria-label="Dodaj do tej grupy dań" onClick={() => handleOpenDialog('addMenuItem', {collection: collection})}>
                                                <AddIcon/>
                                            </IconButton>

                                        </Grid>

                                        {user.restaurant.menu_items && user.restaurant.menu_items.filter(menuItem => menuItem.menu_collection_id === collection.id).map(menuItem => {
                                            return (
                                                <Grid item md={12} sm={12} xs={12} key={"menuItem" + menuItem.id}>
                                                    <RestaurantMenuItem menuItem={menuItem}
                                                                        onClick={() => handleOpenDialog('editMenuItem', menuItem)}
                                                                        onClickDelete={() => handleOpenDialog('deleteMenuItem', menuItem)}
                                                    />
                                                </Grid>
                                            )
                                        })}

                                        {user.restaurant.menu_items && !user.restaurant.menu_items.some(menuItem => menuItem.menu_collection_id === collection.id) && (
                                            <Grid key={"menuItem" + collection.id}>
                                                <Typography gutterBottom variant="body2">Aktualnie nie masz nic dodanego do tej grupy dań.</Typography>
                                            </Grid>
                                        )}

                                    </Box>
                                )
                            })}

                            {user && user.collections && user.collections.length === 0 && (
                                <Box>Brak dodanych grup dań w Twoim menu</Box>
                            )}

                            {user.restaurant.menu_items && user.restaurant.menu_items.filter(menuItem => !menuItem.menu_collection_id).map(menuItem => {
                                return (
                                    <Grid item md={12} sm={12} xs={12} key={"menuItem" + menuItem.id}>
                                        {menuItem.collection}
                                        <RestaurantMenuItem menuItem={menuItem}
                                                            onClick={() => handleOpenDialog('editMenuItem', menuItem)}
                                                            onClickDelete={() => handleOpenDialog('deleteMenuItem', menuItem)}/>
                                    </Grid>
                                )
                            })}

                        </div>
                    )}
                </div>
                </Container>
                </Auth>
        </div>
    );
}

export default Menu;
