import ajaxClient from "./ajaxClient";
import {useLocation} from "@reach/router";

function getLocalizationDataQuery(data) {
    var str = [];
    for (var item in data)
    {
        str.push(encodeURIComponent(item) + "=" + encodeURIComponent(data[item]));
    }

    return str.join("&");
}

export const parseQuery = (search) => {
    const query = new URLSearchParams(
        search
    );

    let data= {};

    for (let params of query.entries()) {
        data[params[0]] = params[1];
    }

    console.log('[parseQuery]', search, data);

    return data;
}

export const getQuery = (data) => {

    return getLocalizationDataQuery(data);

    /**
     * Localization feature, fetch localiztaion, and validate it
     */
    /*try {
        const response = await ajaxClient().post('localization', {
            'address': data.address,
            'latitude': data.latitude,
            'longitude': data.longitude,
            'city': data.city,
            'country': data.country
        }).then(response => response.data);

        return response;
    } catch (error) {
        return error.response;
    }*/
}

export const getQueryLink = (data) => {
    return '/s/' + getQuery(data);
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const contact = async (data) => {
    try {
        const response = await ajaxClient().post('contact', {
            'first_name': data.first_name,
            'last_name': data.last_name,
            'phone': data.phone,
            'email': data.email,
            'landing': data.landing,
            'source': data.source
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}
