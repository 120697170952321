import ajaxClient from "./ajaxClient";

export const getMyRestaurant = async (data) => {
    try {
        const response = await ajaxClient().get('profile/menu/restaurant').then(response => response.data);

        return response;
    } catch (error) {
        return error.response;
    }
}

export const updateDelivery = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/delivery', {
            'has_delivery_house': data.has_delivery_house,
            'has_delivery_pickup': data.has_delivery_pickup,
            'has_delivery_restaurant': data.has_delivery_restaurant,
            'location_limit': data.location_limit,

            'delivery_price': data.delivery_price,
            'surcharge_price': data.surcharge_price,
            'minimum_price': data.minimum_price,
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateRestaurant = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/restaurant', {
            'address': data.address,
            'latitude': data.latitude,
            'longitude': data.longitude,
            'city': data.city,
            'country': data.country,

            'city_name': data.city,
            'country_name': data.country,

            'state': data.state,
            'name': data.name,
            'description': data.description,
            'description_short': data.description_short,
            'image': data.image
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateMenuItem = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/items/' + data.id, {
            'menu_collection_id': data.menu_collection_id,
            'name': data.name,
            'description': data.description,
            'price': data.price,
            'max_quantity': data.max_quantity,
            'image': data.image
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const createMenuItem = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/items', {
            'menu_collection_id': data.menu_collection_id,
            'name': data.name,
            'description': data.description,
            'price': data.price,
            'max_quantity': data.max_quantity,
            'image': data.image,
            'is_box_diet' : data.is_box_diet
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateMenuCollection = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/collections/' + data.id, {
            'name': data.name,
            'description': data.description
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const createMenuCollection = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/collections', {
            'name': data.name,
            'description': data.descriptionS
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const deleteMenuItem = async (data) => {
    try {
        const response = await ajaxClient().delete('profile/menu/items/' + data.id).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const deleteMenuCollection = async (data) => {
    try {
        const response = await ajaxClient().delete('profile/menu/collections/' + data.id).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateRestaurantCompany = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/company', {
            'is_company': data.is_company,
            'company_name': data.company_name,
            'company_tax_id': data.company_tax_id,
            'company_address': data.company_address,
            'company_city': data.company_city,
            'company_postcode': data.company_postcode,
            'company_country': data.company_country,
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateRestaurantOpeningHours = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/openingHours', data).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const confirmOrder = async (order, status, comment) => {
    try {
        const response = await ajaxClient().post('profile/restaurant/orders/' + order.id + '/confirm', {
            'status': status,
            'comment': comment
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

/**
 * Calculate delivery price
 * @param restaurant
 * @param amount
 */
export const calculateDeliveryPrice = (restaurant, amount) => {
    if (!restaurant || (restaurant && !restaurant.surcharge_price)) {
        return 0;
    }

    let total = 0;

    let surchargePrice = restaurant.surcharge_price;
    let deliveryPrice = restaurant.delivery_price;

    if (amount < 25) {
        total = total + surchargePrice;
    }

    return total + deliveryPrice;
}