import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {hasError} from "../../../services/validators";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CustomDialogTitle from "../../Dialogs/CustomDialogTitle";

const useStyles = makeStyles((theme) => ({
    root: {

    }
}));

export default function ConfirmOrderDialog({order, open = false, data = null, onClose, onConfirm}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [errors, setErrors] = React.useState(null);
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const handleClose = () => {
        onClose();
    };

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function submit(data) {
        setIsFormProcessed(true);
        onConfirm(formValues.comment);
        setFormValues({});
        setIsFormProcessed(false);
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <CustomDialogTitle handleClose={handleClose}>Podgląd zamówienia</CustomDialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="comment"
                            label="Komentarz dotyczący realizacji zamówienia"
                            name="comment"
                            autoComplete="comment"
                            autoFocus
                            rows="4"
                            multiline={true}
                            value={formValues.comment}
                            onChange={handleInputChange}
                            error={hasError('comment', errors)}
                            helperText={
                                hasError('comment', errors) ? errors.comment[0] : null
                            }
                        />

                        <Box my={1}>
                            <Divider variant="fullWidth"/>
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions className={classes.fullButtons}>
                    <Button autoFocus onClick={onClose} color="secondary" variant="contained" size="large">
                        Anuluj
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" autoFocus size="large" disabled={isFormProcessed}>
                        {!isFormProcessed ? <Box>Potwierdź</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}