import ajaxClient from "./ajaxClient";
import {useDispatch} from "react-redux";



export const getProfile = async (data) => {
    try {
        const response = await ajaxClient().get('profile').then(response => response.data);

        return response;
    } catch (error) {
        return {data: null};
    }
}

export const updateDelivery = async (data) => {
    try {
        const response = await ajaxClient().post('profile/deliveries', {
            'name': data.name,
            'address': data.address,
            'latitude': data.latitude,
            'longitude': data.longitude,

            'city': data.city,
            'country': data.country,
            'state': data.state

        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updatePersonal = async (data) => {
    try {
        const response = await ajaxClient().post('profile/personal', {
            'name': data.name,
            'email': data.email
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateContact = async (data) => {
    try {
        const response = await ajaxClient().post('profile/contact', {
            'phone': data.phone
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const loginRestaurant = async (restaurantId) => {
    try {
        const response = await ajaxClient().post('loginRestaurant', {
            'id': restaurantId
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const login = async (data) => {
    try {
        const response = await ajaxClient().post('login', {
            'email': data.email,
            'password': data.password
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const userRegister = async (data) => {
    try {
        const response = await ajaxClient().post('register', {
            'name': data.name,
            'email': data.email,
            'password': data.password,
            'cPassword': data.repeatPassword,
            'type': 'client'
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const userCookRegister = async (data) => {
    try {
        const response = await ajaxClient().post('register', {
            'name': data.name,
            'email': data.email,
            'password': data.password,
            'cPassword': data.repeatPassword,
            'type': 'restaurant'
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateCompany = async (data) => {
    try {
        const response = await ajaxClient().post('profile/company', {
            'is_company': data.is_company,
            'company_name': data.company_name,
            'company_tax_id': data.company_tax_id,
            'company_address': data.company_address,
            'company_city': data.company_city,
            'company_postcode': data.company_postcode,
            'company_country': data.company_country,
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateUserCompany = async (data) => {
    try {
        const response = await ajaxClient().post('profile/company', {
            'is_company': data.is_company,
            'company_name': data.company_name,
            'company_tax_id': data.company_tax_id,
            'company_address': data.company_address,
            'company_city': data.company_city,
            'company_postcode': data.company_postcode,
            'company_country': data.company_country,
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}