import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({

    footer: {
        '& a': {
            font: 'normal normal normal 14px/22px Roboto',
            letterSpacing: '0.13px',
            color: '#757575',
            textDecoration:"underline"
        },
        textAlign:'center',
        marginTop: '44px'
    },
    t: {
        font: 'normal normal normal 14px/22px Roboto',
        letterSpacing: '0.13px',
        color: '#757575'
    }
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.footer}>

            <Typography component="h2" className={classes.t}>
                Przechodząc dalej, akceptujesz nasze dokumenty:
            </Typography>

            <Box>
                <Link href="/warunki-i-postanowienia">{"Warunki i postanowienia"}</Link>
                &nbsp;
                <Typography component={"span"} className={classes.t}>i</Typography>
                &nbsp;
                <Link href="/polityka-prywatności">{"Polityka prywatności"}</Link>
            </Box>

        </div>
    );
}