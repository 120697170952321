import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import RatingInfo from "../../RatingInfo/RatingInfo";

export const PaneContainer = styled.div`
    margin-bottom: 1rem;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px;
`;

export const Pane = styled.div`
    padding: 1rem;
    font-family: Roboto;
    line-height: 150%;
    color: #757575;
    font-weight: normal;
    font-size: 16px;
`;

export const CenteredInfo = styled.p`
    text-align: center;
`;

export const DialogWrapper = styled(Dialog)`
    padding: 1rem;
    background: #F3F3F3;
`;

export const CommentDialogTitleWrapper = styled.div`
    font-weight: bold;
    font-size: 28px;
    color: #757575! important;
    background: #F3F3F3;
    padding: 0.5rem 2rem;
`;

export const CommentDialogTitleName = styled.p`
`;

export const CommentDialogRatingTitle = styled.p`
    font-size: 18px;
`;

export const CommentUserName = styled.p`    
    font-weight: bold;
    font-size: 16px;
    color: #FA4A0C;
`;

export const CommentDate = styled.p`
    font-size: 14px;
    color: #757575;
`;

export const CommentContent = styled.p`
    
`;

export const InlineRatingInfo = styled(RatingInfo)`
    display: flex;
`;