import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddLocationIcon from "../../assets/icons/add_location-24px.svg";
import Typography from "@material-ui/core/Typography";
import {CheckCircle} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    whitePage: {
        marginTop: 100,
        '& h2': {
            color: '#FA4A0C',
            font: 'normal normal bold 48px/59px Roboto',
            marginTop: '60px',
            marginBottom: '30px',
            paddingBottom: 0,
            textAlign: 'center',
        },

        [theme.breakpoints.down('md')]: {
            marginTop: '-30px',
        },

        [theme.breakpoints.down('sm')]: {
            marginTop: '-30px',

            '& h2': {
                fontSize: 18,

            },

        },
    },
    whitePageBox: {
        minWidth: '340px',
        maxWidth: '340px',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            maxWidth: '340px',
        },

        marginBottom: '90px',
        zIndex: 1,

        background: '#FFFFFF',
        boxShadow: '-4px 4px 22px rgba(0, 0, 0, 0.15)',
        borderRadius: '20px',

        '& h2': {
            font: 'normal normal bold 24px/18px Roboto',
            letterSpacing: '0.8px',
            color: '#757575',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '& p': {
            font: 'normal normal normal 18px/22px Roboto',
            color: '#757575',
            letterSpacing: '0.6px',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '& .iconWrapper': {
            background: '#7575751A 0% 0% no-repeat padding-box',

            width: 80,
            height: 80,
            borderRadius: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '30px'
        },
        '& .icon': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',

            width: 68,
            height: 68,
            borderRadius: 34,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },

    title: {
        marginTop: '15px',
        fontWeight: 'bold',
        fontSize: '48px',
        letterSpacing: '2px',
        color: '#FFFFFF',
        opacity: '1',
        lineHeight: '130%',

        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            fontSize: 32,
        },
    },

    subtitle: {
        marginTop: '60px',
        fontWeight: 'normal',
        fontSize: 22,
        color: '#FFFFFF',
        opacity: '1',

        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            fontSize: 18,
        },
    },

    h2: {
        marginTop: '60px',
        font: 'normal normal normal 34px/41px Roboto',
        color: '#FFFFFF',
        letterSpacing: '1.13px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            fontSize: 18,
        },
    },

    h3: {
        marginTop: '182px',
        font: 'normal normal bold 48px/59px Roboto',
        letterSpacing: '1.6px',
        color: '#FA4A0C'
    },
    icon: {
        color:'white',
        minWidth: '31px'

    },

}));

function Lp3({type}) {
    const classes = useStyles();

    return (
        <div>
            {type === 'header' && (
                <div>
                    <Typography className={classes.subtitle}>
                        Z nami znajdziesz lepszą alternatywę
                    </Typography>
                    <Typography className={classes.title}>
                        Najlepsze potrawy kuchni wegańskieji wegetariańskiej<br/>tylko u nas.
                    </Typography>

                    <List dense={true}>
                        <ListItem className={classes.title}>
                            <ListItemIcon className={classes.icon}>
                                <CheckCircle />

                            </ListItemIcon>
                            <ListItemText className={classes.text}
                                          primary="Zadbaj o swoje zdrowie i środowisko naturalne."
                            />
                        </ListItem>
                        <ListItem className={classes.title}>
                            <ListItemIcon className={classes.icon}>
                                <CheckCircle />

                            </ListItemIcon>
                            <ListItemText className={classes.text}
                                          primary="Odkryj nowe smaki."
                            />
                        </ListItem>
                        <ListItem className={classes.title}>
                            <ListItemIcon className={classes.icon}>
                                <CheckCircle />

                            </ListItemIcon>
                            <ListItemText className={classes.text}
                                          primary=" Zamawiaj u najlepszych mistrzów kuchni wegańskiej i wegetariańskiej w swojej okolicy."
                            />
                        </ListItem>
                        <ListItem className={classes.title}>
                            <ListItemIcon className={classes.icon}>
                                <CheckCircle />

                            </ListItemIcon>
                            <ListItemText className={classes.text}
                                          primary="Jesteś kucharzem? Dołącz do kucharzy oferujących potrawy wegańskie i wegetariańskie i pokaż innym, że wege-kuchnia to nie tylko zdrowsza, ale i smaczniejsza."
                            />
                        </ListItem>,
                    </List>
                </div>
            )}

            {type === 'description' && (
                <div className={classes.whitePage}>
                    <Container component="main" maxWidth="lg">
                        <Grid item xs={12}>
                            <Grid container justify="space-around" spacing={2}>
                                <Grid item className={classes.whitePageBox}>
                                    <div className={'iconWrapper'}>
                                        <span className={'icon'}>
                                            <img src={AddLocationIcon}/>
                                        </span>
                                    </div>

                                    <Typography component={'p'}>
                                        Masz ochotę na małą przekąskę
                                        czy prawdziwą ucztę?
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.whitePageBox}>
                                    <div className={'iconWrapper'}>
                                        <span className={'icon'}>
                                            <img src={AddLocationIcon}/>
                                        </span>
                                    </div>

                                    <Typography component={'p'}>
                                        Masz ochotę na małą przekąskę
                                        czy prawdziwą ucztę?
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.whitePageBox}>
                                    <div className={'iconWrapper'}>
                                        <span className={'icon'}>
                                            <img src={AddLocationIcon}/>
                                        </span>
                                    </div>

                                    <Typography component={'p'}>
                                        Masz ochotę na małą przekąskę
                                        czy prawdziwą ucztę?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            )}
        </div>

    );
}

export default Lp3;
