import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {showSuccessSnackbar} from "../../actions/ui";
import {hasError} from "../../services/validators";
import {getProfile, updateUserCompany} from "../../services/userService";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import HeaderBar from "../../components/HeaderBar";
import Auth from "./Auth";
import BoxLayout from "../../components/Layouts/BoxLayout";
import Footer from "../Footer";
import {CircularProgress} from "@material-ui/core";
import {updateRestaurantCompany} from "../../services/restaurantService";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h1: {
        textAlign: 'center',

        font: ' normal normal bold 24px/24px Roboto',
        letterSpacing: '0.23px',
        color: '#FA4A0C'
    }
}));

function RestaurantCompany({onSuccess, onError, hideSubmitButton = true}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [user, setUser] = useState(null);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        reloadUser();
    }, []);

    React.useEffect(() => {
        if (user && user.restaurant) {
            console.group('user');
            console.log('user', user);
            console.groupEnd();
            setFormValues(user.restaurant);
        }
    }, [user]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateRestaurantCompany(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                //onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                //onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/profile/personal" titleBar=""/>
            <BoxLayout>
                <Auth>
                    <CssBaseline/>

                    <form onSubmit={handleSubmit} className={classes.form}>
                        <h1 className={classes.h1}>Dane do faktury</h1>

                        <form onSubmit={handleSubmit} className={classes.form}>

                            <TextField
                                type="text"
                                InputProps={{
                                    inputProps: {
                                        min: 0, max: 50
                                    }
                                }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="company_name"
                                label="Nazwa firmy"
                                name="company_name"
                                autoComplete="company_name"
                                autoFocus
                                value={formValues.company_name || ''}
                                onChange={handleInputChange}
                                error={hasError('company_name', errors)}
                                helperText={
                                    hasError('company_name', errors) ? errors.company_name[0] : null
                                }
                            />

                            <TextField
                                type="text"
                                InputProps={{
                                    inputProps: {
                                        min: 0, max: 50
                                    }
                                }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="company_address"
                                label="Ulica"
                                name="company_address"
                                autoComplete="company_address"
                                autoFocus
                                value={formValues.company_address || ''}
                                onChange={handleInputChange}
                                error={hasError('company_address', errors)}
                                helperText={
                                    hasError('company_address', errors) ? errors.company_address[0] : null
                                }
                            />

                            <TextField
                                type="text"
                                InputProps={{
                                    inputProps: {
                                        min: 0, max: 50
                                    }
                                }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="delivery"
                                label="Miasto"
                                name="company_city"
                                autoComplete="company_city"
                                autoFocus
                                value={formValues.company_city || ''}
                                onChange={handleInputChange}
                                error={hasError('company_city', errors)}
                                helperText={
                                    hasError('company_city', errors) ? errors.company_city[0] : null
                                }
                            />

                            <TextField
                                type="text"
                                InputProps={{
                                    inputProps: {
                                        min: 0, max: 50
                                    }
                                }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="delivery"
                                label="Kod pocztowy"
                                name="company_postcode"
                                autoComplete="company_postcode"
                                autoFocus
                                value={formValues.company_postcode || ''}
                                onChange={handleInputChange}
                                error={hasError('company_postcode', errors)}
                                helperText={
                                    hasError('company_postcode', errors) ? errors.company_postcode[0] : null
                                }
                            />

                            <TextField
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="company_tax_id"
                                label="Nip"
                                name="company_tax_id"
                                autoComplete="company_tax_id"
                                autoFocus
                                value={formValues.company_tax_id || ''}
                                onChange={handleInputChange}
                                error={hasError('company_tax_id', errors)}
                                helperText={
                                    hasError('company_tax_id', errors) ? errors.company_tax_id[0] : null
                                }
                            />

                            <Box my={1}>
                                <Divider variant="fullWidth"/>
                            </Box>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={isFormProcessed}
                            >
                                {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                            </Button>
                        </form>
                    </form>
                </Auth>
            </BoxLayout>
            <Footer/>

        </div>


    );
}

export default RestaurantCompany;
