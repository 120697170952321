function getDeliveryDays(datesRange, daysOff){
    console.log('dr: ', datesRange);

    if(datesRange === null || datesRange[0] === null || datesRange[1] === null ){
        return [];
    }

    const dayMilis = 86400000;

    const dateStart = new Date(datesRange[0]).getTime();
    const dateEnd = new Date(datesRange[1]).getTime();

    var selectedDays = [];

    for(var i = dateStart; i <= dateEnd; i+=dayMilis){
        var d = new Date(i);
        if(!daysOff.includes(d.getDay())){
            selectedDays.push(d.getTime());
        }
    }



    return selectedDays;
}

export default getDeliveryDays;