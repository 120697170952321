import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: theme.spacing(2)
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
}));

function RestaurantMenuItem({menuItem, onClick, onClickDelete}) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card className={classes.root}>
            {menuItem.image_filename && (
                <CardMedia
                    className={classes.cover}
                    image={menuItem.image_filename}
                    title={menuItem.name}
                />
            )}
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        {/*{menuItem.id} */}{menuItem.name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {menuItem.description}
                    </Typography>
                </CardContent>
                <div className={classes.controls}>
                    <Button onClick={onClickDelete}>Usuń</Button>
                    <Button onClick={onClick}>Edytuj</Button>
                    {/*<IconButton aria-label="previous">
                        {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                    </IconButton>
                    <IconButton aria-label="play/pause">
                        <PlayArrowIcon className={classes.playIcon} />
                    </IconButton>
                    <IconButton aria-label="next">
                        {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                    </IconButton>*/}
                </div>
            </div>
        </Card>
    )
}

export default RestaurantMenuItem;