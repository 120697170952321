import {navigate} from "@reach/router";
import {useUser} from "../selector";
import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import ajaxClient from "../services/ajaxClient";
import {useDispatch} from "react-redux";
import {Redirect} from "@reach/router/index";
import BoxLayout from "../components/Layouts/BoxLayout";
import HeaderBar from "../components/HeaderBar";
import LoginFooter from "./LoginFooter";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ConfirmPhoneNumber() {
    const classes = useStyles();


    const [confirmationCode, setConfirmationCode] = React.useState("");
    const user = useUser();

    const [errors, setErrors] = React.useState({});
    const [submitted, setSubmitted] = React.useState(false);


    if (!user || !user.name) {
        return <Redirect to="/login" noThrow/>;
    }

    function changeConfirmationCode(e) {
        setConfirmationCode(e.target.value);
    }

    function handleConfirmationCodeSubmit(event) {
        event.preventDefault();
        confirmPhone(user.id, confirmationCode);
    }

    function confirmPhone(userId, confirmationCode) {
        /*ajaxClient().post("profile/confirmPhone", {
            'userId': user.id,
            'confirmationCode': user.confirmationCode
        })*/

        ajaxClient().post("profile/confirmPhone", {
            userId: userId,
            confirmationCode:confirmationCode
        } )
            .then(response => {
                /*const payload = response.data;
                localStorage.session = response.data.token;
                dispatch({ type: "LOGIN", payload });*/
                setSubmitted(true);
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    setErrors(error.response.data);
                } else {
                    throw(error);
                }
            });
    }


    const hasError = (field) => errors[field] ? errors[field] : null;

    return (
        <div className={classes.root}>
            <HeaderBar/>
        <BoxLayout>
            <div className={classes.paper} id="form-content">
                {!submitted &&( <form onSubmit={handleConfirmationCodeSubmit}>

                    {errors && (
                        <div>{errors.error}</div>
                    )}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="confirmationCode"
                        label="Potwierdź numer telefonu"
                        name="confirmationCode"
                        autoComplete="confirmationCode"
                        autoFocus
                        value={confirmationCode}
                        onChange={changeConfirmationCode}
                        error={hasError('confirmationCode')}
                        helperText={
                            hasError('confirmationCode') ? errors.confirmationCode[0] : null
                        }
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Potwierdź numer telefonu
                    </Button>



                </form> )}

                {submitted &&(
                    <div className="welcomeSubscribe">
                        <div>Numer telefonu został potwierdzony</div>
                        <Link href="/" variant="body2">
                            Powrót do strony głównej
                        </Link>
                        <LoginFooter/>
                    </div>

                )}
            </div>
            </BoxLayout>
            <Footer/>
        </div>



    );
}

export default ConfirmPhoneNumber;
