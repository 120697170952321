import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import red from "@material-ui/core/colors/red";

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#FA4A0C',
        },
        secondary: {
            main: '#757575',
            contrastText: 'black',
        },
        disabled: {
            main:'purple[500]',
        },
        default: {
            main: '#FA4A0C',
            contrastText: 'black',
            border: '1px solid #FA4A0C',
            backgroundColor: '#00B24D1F'
        },
        error: red,
        contrastThreshold: 1,
        tonalOffset: 0.2,
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    minHeight: '100%',
                    [defaultTheme.breakpoints.up('md')]: {
                        /*paddingBottom: 150,*/
                    },
                },
                html: {
                    minHeight: '100%',
                    [defaultTheme.breakpoints.up('md')]: {
                        /*paddingBottom: 150,*/
                    },
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 8
            },
            contained: {
                color: '#757575',
                backgroundColor: '#F3F3F3',
                border: '1px solid #B7B7B7',
                boxShadow: 'unset',
                padding: '5px 15px',
                borderRadius: 3,
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                '&:disabled': {
                    backgroundColor: '#FA4A0C',
                    border: '1px solid #FA4A0C',
                    color: 'white',
                },

                [defaultTheme.breakpoints.down('sm')]: {
                    fontSize: 12
                },
            },
            containedPrimary: {
                color: 'white',
                backgroundColor: '#FA4A0C',
                border: '1px solid #FA4A0C',
                boxShadow: '0px 1px 1px #00000029',
                padding: '5px 15px',
                textTransform: 'none',
                borderRadius: 6,
                '&:hover': {
                    '&:disabled': {
                        backgroundColor: '#ff9b77',
                        border: '1px solid #ff9b77',
                        color: 'white',
                    }
                },
                '&:disabled': {
                    backgroundColor: '#ff9b77',
                    border: '1px solid #ff9b77',
                    color: 'white',
                }
            },

            containedSecondary: {
                color: '#FA4A0C',
                backgroundColor: 'white',
                border: '1px solid #FA4A0C',
                boxShadow: '0px 1px 1px #00000029',
                padding: '5px 15px',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                borderRadius: 6
            },
            outlined: {
                color: '#FA4A0C',
                backgroundColor: 'white',
                border: '1px solid #FA4A0C',
                boxShadow: 'unset',
                padding: '5px 15px',
                borderRadius: 3
            },
            outlinedPrimary: {
                color: '#FA4A0C',
                backgroundColor: 'white',
                border: '1px solid #FA4A0C',
                boxShadow: 'unset',
                padding: '5px 15px',
                textTransform: 'none',
                '&:hover': {

                },
                borderRadius: 6
            },
            outlinedSecondary: {
                color: '#FA4A0C',
                backgroundColor: '#EEEEEE',
                border: '1px solid #FA4A0C',
                boxShadow: 'unset',
                padding: '5px 15px',
                textTransform: 'none',
                '&:hover': {

                },
                borderRadius: 6
            },
        },
        MuiDialogTitle: {
            root: {
                paddingTop: '16px',
                '& h2': {
                    color: '#FA4A0C',
                    font: 'normal normal bold 20px/24px Roboto',
                    letterSpacing: '0.19px',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }
            }
        },
        MuiDialogContent: {
            root: {
                padding: '8px 32px',
                [defaultTheme.breakpoints.up('sm')]: {
                    /*padding: '8px 96px',*/
                },
            }
        },
        MuiDialogActions: {
            root: {
                padding: '8px 32px',
                paddingTop: '24px',
                paddingBottom: '24px',
                [defaultTheme.breakpoints.up('sm')]: {
                    /*padding: '8px 96px',*/
                    paddingBottom: '32px'
                },
                '& > .MuiButton-root': {
                    minWidth: 130
                }
            }
        },
        MuiDialogContentText: {
            root: {
                font: "normal normal bold 16px/24px Roboto",
                letterSpacing: "0.15px",
                color: "#757575",
                marginBottom: 32
            }
        }
    },
});

export default theme;
