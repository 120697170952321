import {useUser} from "../../../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {Redirect} from "@reach/router";
import {useFetchProfile} from "../../../actions";
import {hasError} from "../../../services/validators";
import {updateDelivery, updateRestaurant} from "../../../services/restaurantService";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {toNumber} from "../../../services/mainService";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function DeliveryForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const fetchProfile = useFetchProfile();
    const user = useUser();

    React.useEffect(() => {
        fetchProfile();
    }, []);

    React.useEffect(() => {
        if (user && user.restaurant) {
            setFormValues(user.restaurant);
            console.log('formValues', user.restaurant);
        }
    }, [user]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateDelivery(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    if (!user || !user.name) {
        return <Redirect to="/login" noThrow/>;
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>

                <Box>
                    <Box>Dostępne formy dostawy</Box>

                    <FormControlLabel
                        control={<Checkbox checked={formValues.has_delivery_house ?? 'checked'}
                                           onChange={handleInputChange} name="has_delivery_house"/>}
                        label="Dostawa do domu"
                    />

                    <FormControlLabel
                        control={<Checkbox checked={formValues.has_delivery_pickup ?? 'checked'}
                                           onChange={handleInputChange} name="has_delivery_pickup"/>}
                        label="Odbiór osobisty"
                    />

                    <FormControlLabel
                        control={<Checkbox checked={formValues.has_delivery_restaurant ?? 'checked'}
                                           onChange={handleInputChange} name="has_delivery_restaurant"/>}
                        label="Stolik u kucharza"
                    />

                </Box>

                <TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 50
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="delivery"
                    label="Limit dostawy w km"
                    name="location_limit"
                    autoComplete="location_limit"
                    autoFocus
                    value={toNumber(formValues.location_limit,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('location_limit', errors)}
                    helperText={
                        hasError('location_limit', errors) ? errors.location_limit[0] : null
                    }
                />

                <TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 50
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="delivery"
                    label="Koszt dostawy"
                    name="delivery_price"
                    autoComplete="delivery_price"
                    autoFocus
                    value={toNumber(formValues.delivery_price,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('delivery_price', errors)}
                    helperText={
                        hasError('delivery_price', errors) ? errors.delivery_price[0] : null
                    }
                />

                <TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 30
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="surcharge_price"
                    label="Dopłata do zamówienia poniżej 25zł"
                    name="surcharge_price"
                    autoComplete="surcharge_price"
                    autoFocus
                    value={toNumber(formValues.surcharge_price,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('surcharge_price', errors)}
                    helperText={
                        hasError('surcharge_price', errors) ? errors.surcharge_price[0] : null
                    }
                />

                <TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 50
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="minimum_price"
                    label="Minimalna wartość zamówienia"
                    name="minimum_price"
                    autoComplete="minimum_price"
                    autoFocus
                    value={toNumber(formValues.minimum_price,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('minimum_price', errors)}
                    helperText={
                        hasError('minimum_price', errors) ? errors.minimum_price[0] : null
                    }
                />

                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default DeliveryForm;
