import ajaxClient from "./ajaxClient";
import {navigate} from "@reach/router";


export const postOrder = async (order) => {
    try {
        const response = await ajaxClient().post('profile/orders', order).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const getOrders = async (data) => {
    try {
        const response = await ajaxClient().get('profile/orders').then(response => response.data);

        return response;
    } catch (error) {
        return error.response;
    }
}

export const getJoinedProducts = (items) => {
    const options = {  year: 'numeric', month: 'short', day: 'numeric' };
    try {
        var tmp = [];

        for (var i = 0; i < items.length; i++ ) {

            console.log('order item: ', items[i]);


            var istr = items[i].quantity + " x " + items[i].menu_item.name;

            if(typeof(items[i].dates_range) !== 'undefined' && typeof(items[i].delivery_dates) !== 'undefined' && items[i].delivery_dates.length > 0){
                istr += "(" + new Date(items[i].dates_range.start_date).toLocaleDateString(undefined, options)
                + "-" + new Date(items[i].dates_range.end_date).toLocaleDateString(undefined, options) + ": " + items[i].delivery_dates.length + " dostaw)";
            }
            tmp.push(istr);
        }

        return tmp.join(', ');
    } catch (error) {
        return '-';
    }
}

export const processOrder = (order) => {
    console.log('[processOrder]', order);
    if (order.last_payment) {
        if (order.last_payment.payment_id == 2 && order.last_payment.token) {
            navigate(`http://sandbox.przelewy24.pl/trnRequest/` + order.last_payment.token);
            return;
        }
    }

    navigate(`profile/orders/` + order.id);
    return;
}