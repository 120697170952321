import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import HeaderBar from "../../components/HeaderBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {navigate} from "@reach/router";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
    page: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    home__intro: {
        textAlign: 'center'
    }
}));

export default function MenuEmpty() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/home"/>
            <Grid container className={classes.page}>
                <Grid item xs={12} className={classes.home__intro}>
                    <Box mt={15}>
                        <Typography variant="h6" gutterBottom>
                            Obecnie Twoja lista jest pusta
                        </Typography>
                        <br/>
                        <Button size="large" onClick={() => navigate("/profile/menu")} color="primary" variant="contained">Dodaj
                            danie</Button>
                    </Box>
                </Grid>
            </Grid>
            {/*<CookingForm/>*/}
        </div>
    );
}

