export const showSuccessSnackbar = (message, alertType = 'error') => {
    return dispatch => {
        dispatch({ type: "SNACKBAR_SUCCESS", message, alertType });
    };
};

export const clearSnackbar = () => {
    return dispatch => {
        dispatch({ type: "SNACKBAR_CLEAR" });
    };
};
