import {initialState} from "./index";

import { v4 as uuidv4 } from 'uuid';

function cartReducer(state = initialState.cart, action = {}) {
    switch (action.type) {
        case "INCREASE_PRODUCT": {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    quantity: state[action.payload.id].quantity + 1
                }
            };
        }
        case "REMOVE_PRODUCT": {
            const updatedCart = { ...state };
            delete updatedCart[action.payload.id];
            return updatedCart;
        }
        case "DECREASE_PRODUCT": {
            if(state[action.payload.id].quantity - 1 <= 0){
                const updatedCart = { ...state };
                delete updatedCart[action.payload.id];
                return updatedCart;
            }
            else {

                return {
                    ...state,
                    [action.payload.id]: {
                        ...state[action.payload.id],
                        quantity: state[action.payload.id].quantity - 1
                    }
                };
            }
        }
        case "ADD_PRODUCT": {
            var id = action.payload.id;

            if(typeof(action.payload.deliveryDates) !== 'undefined' && action.payload.deliveryDates !== null){
                id = findBoxDietItemIndex(Object.keys(state), state, action.payload);

                if(id === null){
                    id = uuidv4();
                } else {
                    return {
                        ...state,
                        [id]: {
                            ...state[id],
                            quantity: state[id].quantity + 1
                        }
                    };
                }
            }

            return {
                ...state,
                //[action.payload.id]: action.payload
                [id]: action.payload
            };
        }

        /*
        payload: {
            orderItemId,
            datesRange: datesRange,
            deliveryDates: deliveryDates
         */

        case "PRODUCT_DATES_RANGE": {
            console.log('mod order item id: ' + action.orderItemId);

            return {
                ...state,
                [action.orderItemId]: {
                    ...state[action.orderItemId],
                    datesRange: state[action.orderItemId].datesRange,
                    deliveryDays: state[action.orderItemId].deliveryDates
                }
            }
        }

        case "RESET_CART": {
            return {};
        }
        default: {
            return state;
        }
    }


    function addRegularProduct(action){
        return {
            ...state,
            [action.payload.id]: action.payload
        };
    }

    function addBoxDietProduct(action){
        var id = uuidv4();

        return {
            ...state,
            [id]: action.payload
        };
    }

    function regularItemAlreadyAdded(payload){

        for(var p in state){
            if(p === payload.id){
                return true;
            }
        }

        return false;
    }

    function findBoxDietItemIndex(keys, state, orderItem) {
        var found = null;

        for(let k of keys){
            let cartItem = state[k];


            if(cartItem.id === orderItem.id && cartItem.datesRange.startDate === orderItem.datesRange.startDate && cartItem.datesRange.endDate === orderItem.datesRange.endDate){
                found = k;
                break;
            }
        }

        return found;
    }
}




export default cartReducer;
