import HeaderBar from "../components/HeaderBar";
import {navigate} from "@reach/router";
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import {useDispatch} from "react-redux";
import {login} from "../services/userService";
import {showSuccessSnackbar} from "../actions/ui";
import {useUser} from "../selector";
import GeneralError from "../components/GeneralError";
import {hasError} from "../services/validators";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Footer from "./Footer";
import LoginFooter from "./LoginFooter";
import BoxLayout from "../components/Layouts/BoxLayout";

const useStyles = makeStyles((theme) => ({
    h1: {
        textAlign: 'center',
        color: '#2D2D2D',
        fontSize: '24 px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        '& a': {
            font: 'normal normal normal 12px/24px Roboto',
            letterSpacing: '0.11px',
            color: '#757575',
            marginTop: '13px'
        },
        textAlign: "right",
    },

    fb_button: {
        textTransform: "none",
        border: '1px solid #757575',
        boxShadow: 'unset',
        padding: '5px 15px',

        backgroundColor: '#3F5AA9',
        color: '#FFFFFF',
        borderRadius: 6,

        '&:hover': {
            backgroundColor: '#3F5AA9',
        }
    },
    register: {
        '& a': {
            font: 'normal normal bold 20px/28px Roboto',
            letterSpacing: '0.19px',
            color: '#2D2D2D',
        },
        marginTop: '38px',
        textAlign: 'center',
    },
    body1: {
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0.15px',
        color: '#757575'
    },

    login: {
        '& a': {
            font: 'normal normal bold 20px/28px Roboto',
            letterSpacing: '0.19px',
            color: '#757575',
        },
        marginTop: '38px',
        textAlign: 'center',
    },
    body2: {
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0.15px',
        color: '#757575'
    }
}));

function Login() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState("");//mbojkpw@gmail.com
    const [password, setPassword] = React.useState("");//haslo123
    const user = useUser();

    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {

        console.log('user', user);

        if (user.name) {
            navigate("/");
        }
    }, [user]);

    function changeEmail(e) {
        setEmail(e.target.value);
    }

    function changePassword(e) {
        setPassword(e.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        login({email, password}).then(response => {
            let data = response.data;

            if (data && data.error) {
                setErrors(data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {

                localStorage.session = data.token;
                dispatch({type: "LOGIN", payload: data});
                dispatch(showSuccessSnackbar("Zostałeś zalogowany!"));
            }
        });
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/home"/>
            <BoxLayout>
                <CssBaseline/>
                <form onSubmit={handleSubmit}>
                    <div className={classes.h1}>
                        <h1>Zaloguj się</h1>
                    </div>

                    <GeneralError errors={errors}/>
                    <div className={classes.textField}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Adres email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={changeEmail}
                            error={hasError('email', errors)}
                            helperText={
                                hasError('email', errors) ? errors.email[0] : null
                            }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Hasło"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={changePassword}
                            error={hasError('password', errors)}
                            helperText={
                                hasError('password', errors) ? errors.password[0] : null
                            }
                        />
                    </div>
                    <Box className={classes.link}>
                        <Link href="/forgot-password">
                            Przypomnij hasło
                        </Link>
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Zaloguj
                    </Button>

                    <Button

                        fullWidth
                        variant="contained"
                        className={classes.fb_button}
                    >
                        Logowanie przez Facebook
                    </Button>

                    <Box className={classes.login}>
                        <Typography component="h2" className={classes.body1}>
                            Nowy użytkownik?
                        </Typography>

                        <Link href="/register" variant={'body2'}>
                            Załóż konto
                        </Link>

                    </Box>

                    <Box className={classes.register}>
                        <Typography component="h2" className={classes.body1}>
                            Chcesz gotować i sprzedawać?
                        </Typography>


                        <Link href="/register-cook" variant={"body2"}>
                            {"Zarejestruj się jako kucharz"}
                        </Link>
                    </Box>

                    <LoginFooter/>
                </form>
            </BoxLayout>
            <Footer/>
        </div>
    );
}

export default Login;
