import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderBar from "../../components/HeaderBar";
import {Typography} from "@material-ui/core";
import {getProfile} from "../../services/userService";
import {useDispatch} from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import {getOrders} from "../../services/orderService";
import OrderDialog from "../../components/Profile/Dialogs/OrderDialog";
import Card from "@material-ui/core/Card/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import OrderItem from "../../components/Profile/OrderItem";
import {Redirect, useParams} from "@reach/router";
import Auth from "./Auth";
import Comment from "../../components/Comment/Comment";
import ajaxClient from "../../services/ajaxClient";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    cardRoot: {
        display: 'flex',
        marginTop: theme.spacing(2)
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 150,
        height: 150
    },
}));

function Orders() {
    const params = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [orders, setOrders] = useState([]);
    const [user, setUser] = useState(null);

    const [openModalOnce, setOpenModalOnce] = useState(true);

    const [showAddCommentDialog, setShowAddCommentDialog] = useState(false);

    const [ratedRestaurant, setRatedRestaurant] =  useState(null);

    console.log('[Orders] starts');

    const [openDialog, setOpenDialog] = useState({
        'order': {
            open: false,
            data: null
        }
    });

    React.useEffect(() => {
        console.log('[Orders] reloadUser');
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
            setUser(response.data);
        })

        getOrders().then(response => {
            console.log('[Orders][getOrders]', response.data);

            if(response.data.error){
             console.log('error loading orders');
            } else {

                setOrders(response.data);

                if (openModalOnce) {
                    setOpenModalOnce(false);
                    paramsLogic(params, response.data);
                }
            }
        })
    }


    function paramsLogic(params, orders) {
        if (params && params.id) {

            let order = orders.find((order) => {
                return order.id == params.id
            })

            if (order) {
                handleOpenDialog('order', order);
            }
        }
    }

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

    }

    function handleDialogClose(name, result) {
        console.log('handleDialogClose', name);

        reloadUser();

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    function onOpenCommentDialog(order){
        console.log('rating clicked');
        setRatedRestaurant( { 'id' : order.restaurant.id, 'name' : order.restaurant.name});
        handleDialogClose('order');
        setShowAddCommentDialog(true);
        console.log('should display comment dialog: ', showAddCommentDialog);
    }

    async function onCommentSubmitted(data) {

        /*
        'restaurant_id' => 'required|string',
            'user_id' => 'required|string',
            'rating' => 'required|integer',
            'body' => 'required|string'
         */

        console.log('comment: ', {
            'restaurant_id' : data.company.id,
            'rating' : data.rating,
            'body' : data.comment,
            'user_id' : user.id
        });

        await ajaxClient().post('restaurant/makeComment', {
            'restaurant_id' : data.company.id,
            'rating' : data.rating,
            'body' : data.comment,
            'user_id' : user.id
        })
            .then(resp =>{
                console.log(resp);
                setShowAddCommentDialog(false);

                if(resp.status === 200 && orders !== null){

                    orders.map(o => {

                    });

                }

            })
            .catch(err => {
                console.log(err);
                alert(err.toString());
            })
        ;
    }




    return (
        <div className={classes.root}>
            <HeaderBar routePath="/profile/orders" titleBar="Twoje zamówienia"/>
            <Container component="main">
                <Auth>
                    <OrderDialog open={openDialog.order.open} data={openDialog.order.data} onClose={() => handleDialogClose('order')}
                                 onOpenCommentDialog={onOpenCommentDialog}
                    />

                    <Comment company={ ratedRestaurant}
                             isOpen={showAddCommentDialog}
                             setIsOpen={setShowAddCommentDialog}
                             onCommentSubmitted={onCommentSubmitted}/>

                    <div className={classes.paper}>

                        {orders && (
                            <div>
                                <Box pt={2} pb={2} mt={3}>

                                    <Typography gutterBottom variant="h5" component="h2" color="primary">
                                        Moje zamówienia
                                    </Typography>

                                    {orders && orders.map(order => {
                                        return (
                                                <OrderItem order={order} onOpenDialog={handleOpenDialog}/>
                                        )
                                    })}

                                    {orders && orders.length === 0 && (
                                        <Box>Brak aktualnie złożonych zamówień</Box>
                                    )}
                                </Box>

                            </div>
                        )}
                    </div>
                </Auth>
            </Container>
        </div>
    );
}

export default Orders;
