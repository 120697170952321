import {useUser} from "../../../selector";
import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import HeaderBar from "../../HeaderBar";
import {showSuccessSnackbar} from "../../../actions/ui";
import {Redirect} from "@reach/router";
import {useFetchProfile} from "../../../actions";
import {getProfile, updateContact} from "../../../services/userService";
import {hasError} from "../../../services/validators";
import {createMenuItem, updateMenuItem, updateRestaurant} from "../../../services/restaurantService";
import CardMedia from "@material-ui/core/CardMedia";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@material-ui/core/Checkbox';
import FileUploader from "../../FileUploader";
import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        minWidth: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function AddMenuItemForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false, collection = null}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const fileInputRef = useRef(null);

    const [user, setUser] = useState(null);

    React.useEffect(() => {
        if (collection && collection.id) {
            console.log('collection', collection);

            setFormValues({
                'menu_collection_id': collection.id
            })
        }

        reloadUser();
    }, [collection]);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    const handleAttacheInputFile = () => {
        fileInputRef.current.click();
    };

    const handleCheckBoxChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.checked });
        console.log('is_box_diet: ' + formValues[event.target.name]);
    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log('target type: ' + event.target.toString());

        if(target.type === 'checkbox'){
            setFormValues({...formValues, [name.checked] : value});
        } else {

            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    }

    const handleInputFileChange = (event) => {
        const target = event.target;
        const name = target.name;

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function () {

            console.log('setFormValues', reader.result);

            setFormValues({
                ...formValues,
                [name]: reader.result
            });
        };
    };

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        console.log("data submitted, is_box " + formValues.is_box_diet);
        console.log("formValues:" + JSON.stringify(formValues));
        setIsFormProcessed(true);

        createMenuItem(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Grupa dań</InputLabel>
                    <Select
                        fullWidth
                        margin="normal"
                        labelId="menu_collection_id-label"
                        id="menu_collection_id"
                        name="menu_collection_id"
                        value={formValues.menu_collection_id || 0}
                        onChange={handleInputChange}
                        label="Grupa dań"
                        error={hasError('menu_collection_id', errors)}
                        helperText={
                            hasError('menu_collection_id', errors) ? errors.menu_collection_id[0] : null
                        }
                    >
{/*
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
*/}

                        {user && user.collections && user.collections.map(collection => {
                            return (
                                <MenuItem value={collection.id} key={collection.id}>{collection.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Nazwa"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formValues.name}
                    onChange={handleInputChange}
                    error={hasError('name', errors)}
                    helperText={
                        hasError('name', errors) ? errors.name[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    label="Opis"
                    rows="3"
                    multiline={true}
                    name="description"
                    autoComplete="description"
                    autoFocus
                    value={formValues.description}
                    onChange={handleInputChange}
                    error={hasError('description', errors)}
                    helperText={
                        hasError('description', errors) ? errors.description[0] : null
                    }
                />

                <FormControlLabel
                control={
                <Checkbox
                    id="is_box_diet"

                    name="is_box_diet"
                    value={formValues.is_box_diet}
                    onChange={(e) => handleCheckBoxChange(e)}

                />}
                label="Dieta pudełkowa" />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="price"
                    label="Cena"
                    name="price"
                    value={formValues.price}
                    onChange={handleInputChange}
                    error={hasError('price', errors)}
                    helperText={
                        hasError('price', errors) ? errors.price[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="price"
                    label="Dostępność"
                    name="max_quantity"
                    value={formValues.max_quantity}
                    onChange={handleInputChange}
                    error={hasError('max_quantity', errors)}
                    helperText={
                        hasError('max_quantity', errors) ? errors.max_quantity[0] : null
                    }
                />

                {/*
                {formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename && (
                    <CardMedia
                        onClick={handleAttacheInputFile}
                        image={formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename}
                        title={formValues.name}
                    />
                )}
                <input
                    name="image"
                    ref={fileInputRef}
                    accept={'.peg,.png,.jpg,.gif'}
                    type="file"
                    onChange={handleInputFileChange}
                />*/}

                <FileUploader
                    name="image"
                    accept={'.peg,.png,.jpg,.gif'}
                    type="file"
                    image={formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename}
                    title={'Wgraj zdjęcie'}
                    subtitle={'Zalecamy robić zdjęcie w poziomie'}
                    onChange={handleInputFileChange}/>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Dodaj</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default AddMenuItemForm;
