import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        marginBottom: '74.2px',

        boxShadow: '-4px 4px 22px rgba(0, 0, 0, 0.15)',
        borderRadius: 20,

        [theme.breakpoints.up('sm')]: {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },
    h1: {
        textAlign: 'center',
        color: '#FA4A0C',
        fontSize: '24 px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        '& a': {
            font: 'normal normal normal 12px/24px Roboto',
            letterSpacing: '0.11px',
            color: '#757575',
            marginTop: '13px'
        },
        textAlign: "right",
    }
}));

function BoxLayout({children}) {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <div className={classes.overlay}/>
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <Paper className={classes.paper}>
                    {children}
                </Paper>
            </Container>
        </div>

    );
}

export default BoxLayout;
