import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {useJsApiLoader, useLoadScript} from "@react-google-maps/api";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';

import {Search} from "@material-ui/icons";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    light: {
        color: theme.palette.text.secondary,
        backgroundColor: 'white',
        borderRadius: 4
    },
}));

const TextFieldHome = withStyles({
    root: {
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
            borderRadius: 8,
            borderBottom: "none",
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingBottom: "10px"
        },
        '& label.Mui-focused': {
            color: 'green',
            borderBottom: "none"
        }
    },
})(TextField);

const textStyles = makeStyles({
    adornedStart: {
        '& .MuiInputAdornment-positionStart': {
            marginBottom: '13px',
            color: '#757575'
        }
    },
    adornedEnd: {
        '& .MuiInputAdornment-positionEnd': {
            marginTop: '4px',
            color: '#757575'
        }
    }
});

export default function GeoAutoCompleteInput({label = '', onChange, defaultValue, error, helperText, type = 'dark'}) {

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAlCyDwx8W6CXQvVYDcZJO8Wkkvg-c2isI",//AIzaSyAlCyDwx8W6CXQvVYDcZJO8Wkkvg-c2isI
        libraries: ['places']
    })

    const classes = useStyles();
    const textClasses = textStyles();

    const [value, setValue] = React.useState(defaultValue);
    const [inputValue, setInputValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        loaded.current = isLoaded;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        console.log('1. use effect');

        if (!autocompleteService.current && window.google) {
            console.log('2. init service');
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            console.log('3. return');
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results) => {
            console.log('4. results', results);
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, isLoaded]);

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            forcePopupIcon={false}
            noOptionsText={'Wpisz nazwę miasta'}
            disableClearable

            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                console.log('[AC]: newValue', newValue);

                if (newValue) {
                    let geocoder = new window.google.maps.Geocoder();

                    geocoder.geocode({'address': newValue.description}, function handleResults(results, status) {
                        if (status === window.google.maps.GeocoderStatus.OK) {
                            console.log('[AC]:', results);

                            let city = '', state = '', country = '';

                            if (results[0]) {
                                for (var i = 0; i < results[0].address_components.length; i++) {
                                    for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                                        switch (results[0].address_components[i].types[b]) {
                                            case 'locality':
                                                city = results[0].address_components[i].long_name;
                                                break;
                                            case 'administrative_area_level_1':
                                                state = results[0].address_components[i].long_name;
                                                break;
                                            case 'country':
                                                country = results[0].address_components[i].long_name;
                                                break;
                                        }
                                    }
                                }
                            }

                            onChange(event, {
                                    address: newValue.description,
                                    latitude: results[0].geometry.location.lat(),
                                    longitude: results[0].geometry.location.lng(),

                                    city: city,
                                    state: state,
                                    country: country
                                }
                            );
                        }
                    });
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => {
                //console.log('[AC]: params', params);

                if (type === 'home') {
                    return (
                        <TextFieldHome {...params}
                                       error={error}
                                       helperText={helperText}
                                       value={defaultValue}
                                       className={classes.light}
                                       placeholder={'Podaj adres'}

                                       label={false}
                                       variant="filled"
                                       fullWidth

                                       InputProps={{
                                           ...params.InputProps,
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <PlaceOutlinedIcon/>
                                               </InputAdornment>
                                           ),
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <Search/>
                                               </InputAdornment>
                                           ),
                                           classes: {
                                               adornedStart: textClasses.adornedStart,
                                               adornedEnd: textClasses.adornedEnd
                                           }
                                       }}

                        />
                    )
                }

                return (
                    <TextField {...params}
                               error={error}
                               helperText={helperText}
                               value={defaultValue}
                               className={classes.dark}

                               label={label}
                               variant="outlined"
                               fullWidth/>
                )
            }}
            renderOption={(option) => {

                if (!option.structured_formatting) {
                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon}/>
                            </Grid>
                            <Grid item xs>

                            </Grid>
                        </Grid>
                    );
                }

                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                  {part.text}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    )
}