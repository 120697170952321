import {combineReducers} from 'redux'
import cartReducer from "./cart";
import uiReducer from "./ui";
import userReducer from "./user";
import orderReducer from "./order";
import {addRestaurantIdReducer, restaurantsReducer} from "./restaurant";


export const initialState = {
    cart: {},
    user: {
        currentUser: {},
    },
    order: {
        errors: {},
        payment: 'cash',
    },
    isLoggedIn: false,
    restaurantId: ``,
    restaurants: {
        restaurant: {}
    },
    ui: {

    }
};

const reducer = combineReducers({
    cart: cartReducer,
    user: userReducer,
    ui: uiReducer,
    order: orderReducer,
    restaurantId: addRestaurantIdReducer,
    restaurants: restaurantsReducer
});

export default reducer;
