import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HeaderBar from "../components/HeaderBar";
import ajaxClient from "../services/ajaxClient";
import {navigate} from "@reach/router";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    root: {},
    home__intro: {
        textAlign: 'center'
    }
}));

export default function Test() {
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [categories2, setCategories2] = useState([]);
    const [categories3, setCategories3] = useState([]);

    function fetchRestaurant(id) {
        return async dispatch => {
            ajaxClient().get(`restaurants/${id}`)
                .then(response => {
                    const payload = response.data.data;
                    dispatch({type: "FETCH_RESTAURANT", payload});
                })
                .catch(error => {
                    dispatch({type: "DEFAULT"});
                });
        };
    }

    // We have to get chips after we get fish...
    const getFishAndChips = async () => {
        const fish = await fetch(this.fishApiUrl).then(response => response.json());

        const fishIds = fish.map(fish => fish.id),
            chipReqOpts = {method: 'POST', body: JSON.stringify({fishIds})};

        const chips = await fetch(this.chipsApiUrl, chipReqOpts).then(response => response.json());
    }

    async function fetchCategories2() {
        const categories = await ajaxClient().get("categories").then(response => response.data.data);
        const categoryIds = categories.map(category => category.id);
        return categories;
    }

    async function fetchCategories3() {
        const categories = await ajaxClient().get("categories").then(response => response.data.data);
        const categoryIds = categories.map(category => category.id);




        return categoryIds;
    }

    const fetchCategories = async () => {
        const response = await ajaxClient().get("categories");
        return response.data.data;
        //return await response.data.data;
        //setCategories(response.data.data);
        //cities.filter(city => city.agencies.every(e  => e.isDraft)
    }

    React.useEffect(() => {

        fetchCategories().then(data => setCategories(data));
        fetchCategories2().then(data => setCategories2(data));
        fetchCategories3().then(data => setCategories3(data));

        /*(async () => {
            let cat = await fetchCategories();
            setCategories(cat);
        })();

        (async () => {
            let cat = await fetchCategories();
            setCategories(cat);
        })();
*/
    }, []);

    return (
        <div className={classes.root}>

            <HeaderBar routePath="/home"/>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.home__intro}>
                    Component tester

                    {categories && categories.map(categoryItem => {
                        return (
                            <Button
                                variant={categoryItem.slug ? 'outlined' : "contained"}
                                color="default"
                                key={categoryItem.id}
                                className={classes.button}
                                onClick={() => navigate(`/country/city/restaurants/category/${categoryItem.slug}`)}
                            >
                                {categoryItem.name}
                            </Button>
                        );
                    })}

                    <br/>

                    {categories2 && categories2.map(categoryItem => {
                        return (
                            <Button
                                variant={categoryItem.slug ? 'outlined' : "contained"}
                                color="default"
                                key={categoryItem.id}
                                className={classes.button}
                                onClick={() => navigate(`/country/city/restaurants/category/${categoryItem.slug}`)}
                            >
                                {categoryItem.name}
                            </Button>
                        );
                    })}

                    <br/>

                    {categories3 && categories3.map(categoryItem => {
                        return (
                            <div key={categoryItem}>
                                {categoryItem}
                            </div>
                        );
                    })}

                </Grid>
            </Grid>

            {/*<CookingForm/>*/}

        </div>
    );
}
