import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles((theme) => ({

    footer: {
        background: '#000',
        paddingTop: 30,
        paddingBottom: 30,

        '& a': {
            color: 'white',
            display: 'block',
            font: 'normal normal normal 14px/18px Roboto',
            letterSpacing: '0.47px',

            paddingTop: '5px',
            paddingBottom: '5px'
        }
    },
    footerFixed: {
        [theme.breakpoints.up('md')]: {
            zIndex: 1001,
            position: 'fixed',
            left: 0,
            bottom: 0,
            right: 0,
        },

        background: '#000',
        paddingTop: 30,
        paddingBottom: 30,

        '& a': {
            color: 'white',
            display: 'block',
            font: 'normal normal normal 14px/18px Roboto',
            letterSpacing: '0.47px',

            paddingTop: '5px',
            paddingBottom: '5px'
        }
    }
}));

export default function Footer({fixed = false}) {
    const classes = useStyles();

    return (
        <div className={fixed ? classes.footer: classes.footer}>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={3}>
                        <Link href={`/kontakt`}
                              color="default">
                            Kontakt
                        </Link>
                        <Link href={`/jak-zostac-kucharzem`}
                              color="default">
                            Jak zostać kucharzem
                        </Link>
                        <Link href={`/problem-z-zamowieniem`}
                              color="default">
                            Problem z zamówieniem
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={3}>

                        <Link href={`/regulamin`}
                              color="default">
                            Regulamin
                        </Link>

                        <Link href={`/polityka-prywatności`}
                              color="default">
                            Polityka prywatności
                        </Link>

                        <Link href={`/polityka-cookies`}
                              color="default">
                            Polityka cookies
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
