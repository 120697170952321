import React, {useState} from "react";
import './HeaderBar.css';
import {navigate} from "@reach/router";
import {useCartReset, useFetchProfile, useLogout} from "../actions/index";
import Link from "@material-ui/core/Link";

import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

import {useRestaurantId, useUser} from "../selector";
import Box from "@material-ui/core/Box";
import {Dialog, Hidden} from "@material-ui/core";
import {getProfile} from "../services/userService";
import {useDispatch} from "react-redux";
import Logo from '../assets/logo.png';
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: 65
    },
    headerButton: {
        marginRight: theme.spacing(0),

    },
    title: {
        flexGrow: 1,
    },
    headbar: {
        height: '64px',
        backgroundColor: '#FFFFFF',
    },
    userName: {
        textTransform: 'none',
        color: '#757575',
        padding: '6px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        backgroundColor: '250ms',
        cubicBezier: '(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '250ms',
        border: '250ms',
        fontFamily: 'Roboto',
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '8px',
        letterSpacing: '0.53',
        zIndex: 1002,

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 120
    },
    button: {
        textTransform: 'none',
        color: '#757575',
        padding: '6px',
        fontSize: '0.875rem',
        minWidth: '32px',
        boxSizing: 'border-box',
        backgroundColor: '250ms',
        cubicBezier: '(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '250ms',
        border: '250ms',
        fontFamily: 'Roboto',
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '8px',
        letterSpacing: '0.53',
        zIndex: 1002
    },

    menu: {
        width: '330px',
        outline: "none"
    },
    menuButton: {
        fontSize: '16px/24px',
        fontFamily: 'Roboto',
        paddingTop: theme.spacing(1),
        color: '#FA4A0C',
        fontWeight: 'bold',
        letterSpacing: '0.15px'
    },
    menuTitle: {
        font: 'normal normal bold 18px/24px Roboto',
        letterSpacing: '0.22px',
        color: '#757575'
    },

    menuSubtitle: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#757575',
    },

    menuSubButton: {
        color: '#FA4A0C',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: theme.spacing(1)
    },

    menuSubButtonVerify: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        color: 'orange',
        fontFamily: 'Roboto',
        opacity: '0.2'
    },

    logo: {
        maxWidth: 150,
        marginRight: '10px'
    }
}));


function HeaderBar({routePath, titleBar}) {
    const [showDialog, setShowDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [auth, setAuth] = React.useState(true);
    const classes = useStyles();

    const dispatch = useDispatch();
    const fetchProfile = useFetchProfile();
    const user = useUser();
    const logout = useLogout();
    const reset = useCartReset();
    const restaurantId = useRestaurantId();

    const open = Boolean(anchorEl);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
        })
    }

    React.useEffect(() => {
        fetchProfile();
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleYes() {
        setShowDialog(false);
        reset();
        navigate("/");
    }

    console.log('[USER]', user);

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar className={classes.headbar}>
{/*
                    <IconButton edge="start" className={classes.headerButton} aria-label="menu"
                                onClick={() => navigate("/")}>
                        <MenuIcon/>
                    </IconButton>
*/}

                    {<Typography variant="h6" className={classes.title}>
                        <Link href={"#"} onClick={() => navigate("/")}>
                            <img src={Logo} className={classes.logo}/>
                        </Link>
                    </Typography>}

                    {titleBar === "Restaurant" && (
                        <IconButton edge="start" className={classes.headerButton} aria-label="menu"
                                    onClick={() => setShowDialog(true)}>
                            <Dialog open={showDialog} onClose={() => setShowDialog(true)}>
                                <p>Czy oby napewno chcesz wyjść z zamówienia</p>
                                <button onClick={handleYes}>
                                    <Link to="/">Tak</Link>
                                </button>
                                <button onClick={() => setShowDialog(false)}>
                                    Nie
                                </button>
                            </Dialog>
                        </IconButton>
                    )}

                    <Button onClick={() => navigate("/restaurants")} className={classes.button}>Kucharze</Button>

                    {(user && user.id) ? (
                        <Box>
                            {user && (user.type === 'admin' || user.type === 'client' || user.type === '') && restaurantId && (
                                <Button onClick={() => navigate("/order")} className={classes.button}>Koszyk</Button>
                            )}

                            <IconButton className={classes.button}
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                            >
                                <Hidden mdDown>
                                    <Typography className={classes.userName}>{user.name}</Typography>
                                </Hidden>

                                <AccountCircleOutlinedIcon/>
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >

                                <Box className={classes.menu} pl={2.5} pr={2.5} pt={1} pb={1}>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Box className={classes.menuTitle}>Twój profil</Box>
                                        <Link href="/profile/personal" className={classes.menuButton}>
                                            Edytuj
                                        </Link>
                                    </Box>
                                    <Box pt={1}>
                                        <Box className={classes.menuSubtitle}>Twoja nazwa</Box>
                                        <Box fontWeight="fontWeightBold" className={classes.menuSubtitle}>
                                            {user.name}
                                        </Box>
                                    </Box>
                                    <Box pt={1} pb={2}>
                                        <Box className={classes.menuSubtitle}>Adres email</Box>
                                        <Box fontWeight="fontWeightBold" className={classes.menuSubtitle}>
                                            {user.email}
                                        </Box>
                                    </Box>

                                    <Divider />

                                    <Box pt={2} pb={2} display={"flex"} justifyContent={"space-between"}>
                                        <Box>
                                            <Box className={classes.menuSubtitle}>Telefon</Box>
                                            <Box fontWeight="fontWeightBold" className={classes.menuSubtitle}>
                                                {user.phone ? user.phone : 'brak'}
                                            </Box>
                                        </Box>
                                        <Box pt={"27px"}>
                                            {!user.phone_verified && (
                                                <Box className={classes.menuSubButtonVerify}>
                                                    Zweryfikuj
                                                </Box>
                                            )}
                                            <Link href="/profile/contact" className={classes.menuButton}>
                                                Edytuj
                                            </Link>
                                        </Box>
                                    </Box>

                                    <Divider pt={2} pb={2}/>

                                    {user && (user.type === 'restaurant' || user.type == 'admin') && (
                                        <Box>
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                <Link href="/profile/menu">
                                                    <Box className={classes.menuTitle}>Zarządzaj menu</Box>
                                                </Link>
                                            </Box>
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                <Link href="/profile/restaurant/orders">
                                                    <Box className={classes.menuTitle}>Obsługa zamówień</Box>
                                                </Link>
                                            </Box>
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                <Link href="/profile/restaurant/company">
                                                    <Box className={classes.menuTitle}>Dane do faktury</Box>
                                                </Link>
                                            </Box>
                                        </Box>
                                    )}

                                    {user && user.type === 'client' && (
                                        <Box>
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                <Link href="/profile/delivery">
                                                    <Box className={classes.menuTitle}>Dane dostawy</Box>
                                                </Link>
                                            </Box>
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                <Box className={classes.menuTitle}>
                                                    <Link href="/profile/orders">
                                                        Moje zamówienia
                                                    </Link>
                                                </Box>

                                            </Box>
                                            <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                <Box className={classes.menuTitle}>
                                                    <Link href="/profile/company">
                                                        Dane do faktury
                                                    </Link>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                        <Box className={classes.menuTitle}>
                                            <Link href="#" onClick={logout} className={classes.menuButton}>
                                                Wyloguj
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Menu>
                        </Box>
                    ) : (
                        <Button onClick={() => navigate("/login")} className={classes.button}>Zaloguj się</Button>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default HeaderBar;
