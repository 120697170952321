import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from "@material-ui/core/Button";

import BasicDateRangePicker from "../BasicDateRangePicker";
import BoxDietDatesCost from "../BoxDietDatesCost";
import DialogContentText from "@material-ui/core/DialogContentText";
import getDeliveryDays from "../../middlewares/deliveryDays";
import styled from "styled-components"




const SkinnyDialog = styled(Dialog)`
     
     @media screen and (max-width: 420px) {
     
     .MuiDialogContent-root {
       padding: 0;
     }
     
     .MuiDialogActions-root > .MuiButton-root {
       min-width: 50px;
        }
     
        .MuiPickersStaticWrapper-staticWrapperRoot{
            display: flex;
            overflow: hidden;
            min-width: 200px;
            flex-direction: column;
            background-color: #fff;
        }
     
     }
     
     .pricesGrid.MuiGrid-spacing-xs-3 {
       width: 100%;
       padding: 0 5%;
        }
     
     `;


 function BoxDietDatesSelectDialog({isOpen, onDialogClosed, onDatesRangeSelected, currentDatesRange, daysOff, unitPrice}){



    const [dateStart, setDateStart] = React.useState( currentDatesRange === null ? null : currentDatesRange.startDate);

    const [dateEnd, setDateEnd] = React.useState(currentDatesRange === null ? null : currentDatesRange.endDate);

    const [deliveryDates, setDeliveryDates] = React.useState( currentDatesRange === null ? [] : getDeliveryDays([currentDatesRange.startDate, currentDatesRange.endDate], daysOff) );

    const [datesError, setDatesError] = React.useState(false);








    function handleDialogClose(){
        onDialogClosed();
    }


    function onDatesRangeChanged(dateRange, deliveryDays){
        console.log(dateRange);
        setDatesError(false);

        if(dateRange[0] === null){
            setDateStart(null);
        } else {
            setDateStart(dateRange[0]);
        }

        if(dateRange[1] === null){
            setDateEnd(null);
        } else {
            setDateEnd(dateRange[1]);
        }

        setDeliveryDates(deliveryDays);
    }

    function onDatesRangeAccepted(){

        if(validateDatesRange()){
            console.log('BoxDietDatesSelectDialog start: ', dateStart);
            console.log('BoxDietDatesSelectDialog end: ', dateEnd);
            onDatesRangeSelected(dateStart, dateEnd, deliveryDates);
            onDialogClosed();

        } else {
            setDatesError(true);
        }
    }

    function validateDatesRange(){
        return dateStart !== null && dateEnd !== null;
    }



    return (

            <SkinnyDialog
                fullScreen={false}
                fullWidth={false}
                maxWidth={'sm'}
                open={isOpen}
                onClose={handleDialogClose}
            >

                <DialogContent >

                    {datesError &&
                    <DialogContentText>
                        Data początkowa i końcowa muszą być wybrane.
                    </DialogContentText>
                    }

                    <BasicDateRangePicker
                        selectedDate = {currentDatesRange}
                        onDatesRangeChanged = {onDatesRangeChanged}
                        daysOff={daysOff}
                    />
                    {/*{dateStart, dateEnd, numDays, numItems, unitPrice}*/}
                    <BoxDietDatesCost dateStart={dateStart} dateEnd={dateEnd}
                                      numDays={ deliveryDates === null || typeof(deliveryDates) === 'undefined'? 0 : deliveryDates.length}
                                      numItems={1} unitPrice={unitPrice}  />
                </DialogContent>
                <DialogActions >
                    <Button autoFocus color="secondary" variant="contained" size="large" onClick={handleDialogClose}>
                        Anuluj
                    </Button>
                    <Button onClick={onDatesRangeAccepted} color="primary" variant="contained" autoFocus size="large">
                        Utwórz
                    </Button>
                </DialogActions>
            </SkinnyDialog>

    )

}


export default BoxDietDatesSelectDialog;