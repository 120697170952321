import React, {useState} from "react";
import Restaurant from "../components/Restaurant";
import ajaxClient from "../services/ajaxClient";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {navigate, useMatch} from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {getQueryLink, parseQuery} from "../services/localizationService";

const useStyles = makeStyles((theme) => ({
    categories: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        overflow: 'hidden',
        paddingBottom: theme.spacing(2)
    },
    categoriesBox: {
        display: 'flex',
        overflowY: 'auto',
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    button: {
        marginRight: theme.spacing(1),
        flexShrink: 0,
    },
}));


function RestaurantCategories({query = null}) {
    const classes = useStyles();

    console.log('[query category]', query);

    const [categories, setCategories] = useState([]);
    const [formValues, setFormValues] = useState(null);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);

    return (
        <section className={classes.categories}>
            <Box pt={2} pb={2} className={classes.categoriesBox}>
                <Button
                    variant={formValues && formValues.category === '' ? 'outlined' : "contained"}
                    color="default"
                    key={0}
                    className={classes.button}
                    onClick={() => navigate(getQueryLink({...formValues, 'category': ''}))}
                >
                    Wszystkie
                </Button>

                {categories && categories.map(categoryItem => {
                    return (
                        <Button
                            variant={formValues.category === categoryItem.name ? 'outlined' : "contained"}
                            color="default"
                            key={categoryItem.id}
                            className={classes.button}
                            onClick={() => navigate(getQueryLink({...formValues, 'category': categoryItem.name}))}
                        >
                            {categoryItem.name}
                        </Button>
                    );
                })}

            </Box>
        </section>
    );
}

export default RestaurantCategories;
