import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import RatingPane from "./RatingPane";
import { CommentDialogTitleWrapper, CenteredInfo, CommentDialogTitleName, CommentDialogRatingTitle} from './styles/styles'
import RatingInfo from "../RatingInfo/RatingInfo";

export default  function RatingsList({companyName, showRatingsList=true, setShowRatingsList, companyRating = 0, numRatings = 0, ratings = []}) {

    console.log('show ratings list: ', showRatingsList);

    function closeBgClick(){
        console.log('bg close called');
        setShowRatingsList(false);
    }

    return (<Dialog
        open={showRatingsList}
        scroll='paper'
        onBackdropClick={closeBgClick}
        fullWidth
    >


        <CommentDialogTitleWrapper>
            <CommentDialogTitleName> {companyName}  </CommentDialogTitleName>
            <CommentDialogRatingTitle>Średnia ocena:</CommentDialogRatingTitle>

            <RatingInfo rating={companyRating} numVotes={numRatings} />

         </CommentDialogTitleWrapper>



        <DialogContent>
        {ratings === null || ratings.length === 0?(
            <CenteredInfo
            variant="p" class="text-center">
                Brak wyników
            </CenteredInfo>)
            :
            ratings.map(item => (<RatingPane

                author={item.name}
                date={new Date(item.created_at).toLocaleDateString()}
                content={item.body}
                rating={item.rating}
            />))
        }

        </DialogContent>


    </Dialog>)

}