import React, {useEffect, useRef, useState} from 'react';
import {GoogleMap, InfoWindow, LoadScript, Marker, useJsApiLoader} from '@react-google-maps/api';
import {makeStyles} from "@material-ui/core/styles";
import RestaurantOnMap from "./RestaurantOnMap";

/*
.gm-style .gm-style-iw-c {
   padding-right: 10px !important;
    padding-bottom: 0px !important;
    max-width: 500px !important;
    max-height: 326px !important;
    min-width: 0px !important;
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate(-50%,-100%);
    background-color: #dd9191;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}
 */

const exampleMapStyles = [
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
];

const useStyles = makeStyles({
    infowindow: {
        padding: 0
    },
    marker: {
        outline: 'none',
        color: 'red',
        '&:hover': {
            outline: 'none'
        }
    }
});

const MapContainer = ({restaurants}) => {

    /*const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        loadGoogleMaps(() => {
            setLoaded(true);
        });
    });*/

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAlCyDwx8W6CXQvVYDcZJO8Wkkvg-c2isI",//
        libraries: ['places']
    })

    console.log('RESTAURANTS', 1);

    const classes = useStyles();

    const [selected, setSelected] = useState({});
    const [currentPosition, setCurrentPosition] = useState({});

    const markerRef = useRef(null);

    const defaultCenter = {
        lat: 41.3851, lng: 2.1734
    }

    const onSelect = item => {
        setSelected(item);
    }

    const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const currentPosition = {
            lat: latitude,
            lng: longitude
        }
        setCurrentPosition(currentPosition);
    }

    const onMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setCurrentPosition({lat, lng})
    };

    const mapStyles = () => {
        return {
            /*marginTop: "-20px",*/
            height: "100vh",
            width: "100%"
        }
    }

    useEffect(() => {
        //console.log('getCurrentPosition');
        //navigator.geolocation.getCurrentPosition(success);

        if (restaurants && restaurants.length > 0) {
            let restaurant = restaurants[0];
            setCurrentPosition(restaurant.location);
        }


    }, [restaurants])


    const handleMapClick = (e) => {
        setSelected({});
    };

    return (
        <>
            {isLoaded && (
                <GoogleMap
                    id='example-map'
                    mapContainerStyle={mapStyles()}
                    draggable={true}
                    zoom={13}
                    center={currentPosition.lat ? currentPosition : defaultCenter}
                    onClick={handleMapClick}
                    options={{clickableIcons: false, styles: exampleMapStyles}}
                >

                    {restaurants ?
                        restaurants.map(restaurant => {
                            console.group('Maps');

                            if (!restaurant.latitude || !restaurant.longitude) {
                                console.log('[MAP][Restaurant]: not exists', restaurant);
                                return null;
                            }

                            console.log('restaurant', restaurant.location);
                            console.groupEnd();

                            return (
                                <Marker
                                    key={restaurant.id}
                                    position={restaurant.location}
                                    onClick={() => onSelect(restaurant)}
                                    draggable={false}
                                />
                            )
                        }) : null
                    }

                    {selected.location ?
                        (
                            <InfoWindow
                                className={classes.infowindow}
                                position={selected.location}
                                onCloseClick={() => setSelected({})}
                            >
                                <div>
                                    {<RestaurantOnMap restaurant={selected}/>}
                                    {/*<p>{selected.title}</p>
                                    <img src={selected.image} className="small-image" alt="rental"/>
                                    <p>price: {selected.price}</p>
                                    <p>sqm2: {selected.sqm}</p>
                                    <p>bedrooms: {selected.bedrooms}</p>*/}
                                </div>
                            </InfoWindow>
                        ) : null
                    }
                </GoogleMap>
            )}
        </>
    )
}

export default MapContainer;