import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Link} from "@reach/router";
import Box from "@material-ui/core/Box";
import './style.css';

const useStyles = makeStyles({
    card: {
        width: 250,
        maxWidth: 250
    },
    restaurant_url: {
        textDecoration: 'none',
        color: '#757575'
    },
    restaurant_name: {
        font: 'normal normal bold 14px/18px Roboto',
        letterSpacing: '0.14px',
        color: '#757575',
        marginTop: '5px'

    },
   restaurantDescription:{
       font: 'normal normal normal 14px/18px Roboto',
       letterSpacing: '0.14px',
       color: '#757575',
       marginTop: '9px',
       whiteSpace: 'pre-line'
   }
});

export default function RestaurantOnMap({restaurant}) {
    const classes = useStyles();

    return (
        <Link to={`${restaurant.url}`} className={classes.restaurant_url}>
            <Box className={classes.card}>
                <CardMedia
                    component="img"
                    alt={restaurant.name}
                    height="170"
                    image={restaurant.image_filename}
                    title="Contemplative Reptile"
                />
                <Box p={2}>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.restaurant_name}>
                        {restaurant.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.restaurantDescription}>
                        {restaurant.description}
                    </Typography>
                </Box>
            </Box>
            <br/>
        </Link>
    );
}
