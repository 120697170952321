import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getJoinedProducts} from "../../services/orderService";

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 300,
        fontSize: 14
    }
}));

function OrderItemProducts({items}) {
    const classes = useStyles();

    return (
        <span className={classes.root}>
            {getJoinedProducts(items)}

            {/*2 x Ser, 3 x Burger, 5 x Woda, 1 x Pepsi, 1 x Rollo Mega - pszenna*/}
        </span>
    )
}

export default OrderItemProducts;
