import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Background from '../assets/bg.png'; // Import using relative path
import Typography from "@material-ui/core/Typography";
import GeoAutoCompleteInput from "../components/GeoAutoCompleteInput";
import Container from "@material-ui/core/Container";
import {navigate} from "@reach/router";
import {getQueryLink} from "../services/localizationService";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    page: {
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        maxHeight: '240px',
        height: '240px'
    },
    pageWrapper: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pageOverlay: {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.1,
        zIndex: 0,
        position: "absolute",
        maxHeight: '410px'
    },
    pageContainer: {
        zIndex: 1,
        position: "relative"
    },
    pageIntro: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
    },

    search: {
        zIndex: 1001,
        height: '100%'
    },

    geoInput: {
        minWidth: '500px',
        maxWidth: '500px',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            minWidth: '300px',
            maxWidth: '300px',
        },
    },

    h2: {
        font: 'normal normal normal 30px/36px Roboto',
        color: '#FFFFFF',
        letterSpacing: '1.13px',
        marginBottom: '20px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            font: 'normal normal normal 30px/36px Roboto',
        },
    },
}));

export default function SearchBar({onSearch}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    function handleInputAddressChange(event, data) {
        submit({
            ...formValues,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,

            city: data.city,
            state: data.state,
            country: data.country
        });
    }

    function handleClick(event) {
        submit(formValues);
    }

    function submit(data) {
        if (onSearch) {
            onSearch(data);
        } else {
            navigate(getQueryLink(data));
        }
    }

    return (
        <div>
            <div className={classes.page}>
                <div className={classes.pageWrapper}>
                    <div className={classes.pageOverlay}></div>

                    <Container component="main" maxWidth="lg">
                        <Grid container className={classes.pageContainer}>
                            <Grid item xs={12} className={classes.pageIntro}>

                                <Typography className={classes.h2}>
                                    Podaj adres i zobacz co przygotowali kucharze
                                </Typography>

                                <div className={classes.search}>
                                    <div className={classes.geoInput}>
                                        <GeoAutoCompleteInput
                                            label={'Podaj adres'}
                                            defaultValue={formValues.address || ''}
                                            onChange={handleInputAddressChange}
                                            error={false}
                                            type={'home'}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </div>
    );
}
