import {navigate} from "@reach/router";
import {useUser} from "../selector";
import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import ajaxClient from "../services/ajaxClient";
import {useDispatch} from "react-redux";
import HeaderBar from "../components/HeaderBar";
import BoxLayout from "../components/Layouts/BoxLayout";

const useStyles = makeStyles((theme) => ({
    h1: {
        textAlign: 'center',
        color: '#2D2D2D',
        fontSize: '24 px'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ResetPassword(props) {
    console.log(props);
    const classes = useStyles();
    const dispatch = useDispatch();

    const [password, setPassword] = React.useState("");
    const [passwordConfirm, setPasswordConfirm] = React.useState("");
    const [email, setEmail] = React.useState("");

    const user = useUser();

    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        if (user.name) {
            navigate("/");
        }
    }, [user]);


    function changeEmail(e){
        setEmail(e.target.value);
        setErrors({});
    }

    function changePassword(e) {
        setPassword(e.target.value);
        setErrors({});
    }

    function changePasswordConfirm(e) {
        setPasswordConfirm(e.target.value);
        setErrors({});
    }

    function handleSubmit(event) {
        event.preventDefault();
        changePasswordData({password: password, passwordConfirm: passwordConfirm});
    }

    function changePasswordData(passwordData) {
        // console.log("null password: " + (passwordData == null));
        // console.log(JSON.stringify(passwordData));
        if(passwordData == null || typeof (passwordData.password) === 'undefined'
            || typeof (passwordData.passwordConfirm) === 'undefined'){
            setErrors({error: "Błędne dane hasła"});
            return;
        }

        if(passwordData.password !== passwordData.passwordConfirm) {
            setErrors({error: "Hasło i jego powtórzenie muszą być takie same."});
            return;
        }


        var token = props.token;

        ajaxClient().post("reset-password", {
            'email': email,
            'token': token ,
            'password': passwordData.password,
            'password_confirmation': passwordData.password
        })
            .then(response => {
                /*const payload = response.data;
                localStorage.session = response.data.token;
                dispatch({ type: "LOGIN", payload });
                // this.props.history.push("/login");*/
                console.log(response);

                var payload = {};
                dispatch({ type: "PASSWORD_RESET", payload });
                navigate("/");

            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    setErrors(error.response.data);
                } else {
                    throw(error);
                }
            });
    }

    const hasError = (field) => errors[field] ? errors[field] : null;

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/home"/>
            <BoxLayout>
                <CssBaseline/>
                <form onSubmit={handleSubmit}>
                    <div className={classes.h1}>
                        <h1>Resetuj hasło</h1>
                    </div>

                    {errors && (
                        <div>{errors.error}</div>
                    )}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Adres email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={changeEmail}
                        error={hasError('email')}
                        helperText={
                            hasError('email') ? errors.password[0] : null
                        }
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        label="Nowe hasło"
                        name="password"
                        type="password"
                        value={password}
                        onChange={changePassword}
                        error={hasError('password')}
                        helperText={
                            hasError('password') ? errors.password[0] : null
                        }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="passwordConfirm"
                        label="Powtórz hasło"
                        type="password"
                        id="passwordConfirm"

                        value={passwordConfirm}
                        onChange={changePasswordConfirm}
                        error={hasError('passwordConfirm')}
                        helperText={
                            hasError('passwordConfirm') ? errors.passwordConfirm[0] : null
                        }
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Resetuj
                    </Button>

                    <Link href="/register" variant="body2">
                        {"Nie masz konta? Zarejestruj się"}
                    </Link>
                </form>
            </BoxLayout>
        </div>
    );
}

export default ResetPassword;
