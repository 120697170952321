import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import RealizedIcon from "../../assets/icons/status/realized.svg";


const useStyles = makeStyles((theme) => ({
    status: {
        textAlign: "center",
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '150%',
        color: '#757575'
}
}));

function OrderProgress({order, type = 'client'}) {
    const classes = useStyles();
    console.log('[OrderItems]', order);

    return (
      <Box pt={1} pb={2}>
              {type === 'client' && (
                  <Box textAlign={"center"}>
                      {(order.status === 'new' && (
                          <Typography variant="p" className={classes.status}>
                              Oczekuj na potwierdzenie zamówienia
                          </Typography>
                      ))}

                      {(order.status === 'confirmed' && (
                          <Typography variant="p" className={classes.status}>
                              Kucharz potwierdził Twoje zamówienie
                          </Typography>
                      ))}
                  </Box>
              )}

              {type === 'restaurant' && (
                  <Box textAlign={"center"}>
                      {(order.status === 'new' && (
                          <Typography variant="p" className={classes.status}>
                              Potwierdź realizację zamówienia
                          </Typography>
                      ))}

                      {(order.status === 'confirmed' && (
                          <Typography variant="p" className={classes.status}>
                              Zamówienie potwierdzone
                          </Typography>
                      ))}
                  </Box>
              )}
      </Box>
    );
}

export default OrderProgress;
