import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {hasError} from "../../../services/validators";
import {updateMenuCollection, updateRestaurant} from "../../../services/restaurantService";
import Box from "@material-ui/core/Box";
import {useFetchProfile} from "../../../actions";
import {useUser} from "../../../selector";
import {Redirect} from "@reach/router";
import GeoAutoCompleteInput from "../../GeoAutoCompleteInput";
import GeoStaticMap from "../../GeoStaticMap";
import FileUploader from "../../FileUploader";
import Divider from "@material-ui/core/Divider";
import {contact} from "../../../services/localizationService";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    file: {
        width: 200,
        height: 150
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    agreeLabel: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Roboto',
        lineHeight: '20px',

        '& a': {
            'color': '#FA4A0C'
        }
    },
}));

function LandingPageForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false, landing = '', source = ''}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({landing: landing, source: source});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        contact(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="first_name"
                    label="Imię"
                    name="first_name"
                    autoComplete="first_name"
                    autoFocus
                    value={formValues.first_name || ''}
                    onChange={handleInputChange}
                    error={hasError('first_name', errors)}
                    helperText={
                        hasError('first_name', errors) ? errors.first_name[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="last_name"
                    label="Nazwisko"
                    name="last_name"
                    autoComplete="last_name"
                    autoFocus
                    value={formValues.last_name || ''}
                    onChange={handleInputChange}
                    error={hasError('last_name', errors)}
                    helperText={
                        hasError('last_name', errors) ? errors.last_name[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phone"
                    label="Numer telefonu"
                    name="phone"
                    autoComplete="phone"
                    autoFocus
                    value={formValues.phone || ''}
                    onChange={handleInputChange}
                    error={hasError('phone', errors)}
                    helperText={
                        hasError('phone', errors) ? errors.phone[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Adres email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formValues.email || ''}
                    onChange={handleInputChange}
                    error={hasError('email', errors)}
                    helperText={
                        hasError('email', errors) ? errors.email[0] : null
                    }
                />

                <Box my={3}>
                    <FormControlLabel
                        control={<Checkbox checked={formValues.agree_1 ?? 'checked'}
                                           onChange={handleInputChange} name="agree_1"/>}
                        label={(<Box className={classes.agreeLabel}>* Akceptacja <a href="/regulamin">Regulaminu</a> i <a href="/polityka-prywatnosci">Polityki prywatności</a> House food</Box>)}
                    />
                </Box>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size={"large"}
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Wyślij</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default LandingPageForm;
