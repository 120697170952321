import React from 'react';

import Typography from "@material-ui/core/Typography";

import DateRangeIcon from '@material-ui/icons/DateRange';
import BoxDietDatesSelectDialog from "./BoxDietDatesSelectDialog";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles((theme) => ({

    dates_box: {
    width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
}

}));


function BoxDietDates( {datesRangeSelected, onDatesRangeSelected, daysOff, unitPrice} ) {

    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    // var datesLabel = "Nie wybrano";
    const [datesLabel, setDatesLabel] = React.useState(
        (typeof(datesRangeSelected) !== 'undefined' && datesRangeSelected !== null && datesRangeSelected.dateStart !== null && datesRangeSelected.dateEnd !== null) ?
            buildDatesLabel(datesRangeSelected): "Nie wybrano");

    //selectedDate.startDate

    console.log('initial dates range selected: ', datesRangeSelected);


    /*if(typeof(datesRangeSelected) !== 'undefined' && datesRangeSelected !== null && datesRangeSelected[0] !== null && datesRangeSelected[1] !== null){
        buildDatesLabel(datesRangeSelected);
    }*/


    /*function updateDatesSelected(dr){
        onDatesRangeSelected(dr);
        buildDatesLabel(dr);
    }*/

    function buildDatesLabel(dr) {
        console.log('dates: ', dr);
        var tempdatesLabel = dr.dateStart !== null ? formatDate( dr.dateStart) : "Brak";
        tempdatesLabel += '-';
        tempdatesLabel += dr.dateEnd !== null ? formatDate(dr.dateEnd) : "Brak";
        return tempdatesLabel;
    }


    function formatDate(date) {
        console.log('date to format ', date);
        const options = {  year: 'numeric', month: 'short', day: 'numeric' };

       /* if(typeof(date) === 'undefined' || date === null){
            return;
        }

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year ].join('/');*/
       return new Date(date).toLocaleDateString(undefined, options);
    }


    function dateRangeIconClick(){
        setDialogOpen(true);
    }


    function onDatesSelected(dateStart, dateEnd, deliveryDates){
        console.log('box diet dates: ', deliveryDates);
        var dateObj = {
            dateStart: new Date(dateStart),
            dateEnd: new Date(dateEnd),
        };

        setDatesLabel(buildDatesLabel(dateObj));

        onDatesRangeSelected(dateStart, dateEnd, deliveryDates);

    }

    function onDialogClosed(){
        setDialogOpen(false);
    }

    return <div className={classes.dates_box}>

        <Typography variant="body2" color="textSecondary" component="p">
            Okres dostaw
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
            {datesLabel}
        </Typography>

        <IconButton  aria-label="Dates select"  color="primary" onClick={dateRangeIconClick}>
            <DateRangeIcon  />
        </IconButton >


        <BoxDietDatesSelectDialog
            isOpen={dialogOpen}
            onDialogClosed={onDialogClosed}
            onDatesRangeSelected={onDatesSelected}
            currentDatesRange = {{
                startDate: datesRangeSelected.dateStart,
                endDate: datesRangeSelected.dateEnd
            }}
            daysOff={daysOff}
            unitPrice={unitPrice}
        />


    </div>


}

export default BoxDietDates;