import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import OrderItemProducts from "../OrderItemProducts";
import Price from "../../Price";
import OrderProgress from "../../Order/OrderProgress";
import Loader from "../../Loader";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    header: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#757575',
        textTransform: 'capitalize',
        display: 'flex',
        flexBasis: '100%',
        borderBottom: '1px solid lightgrey'
    },

    padded: {
      padding: 8
    },

    imageWithText: {
        backgroundColor: "white",
        display: 'flex',
        alignItems: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto',
        paddingTop: 10
    },
    imageWithTextCardMedia: {
        width: 64,
        height: 64,
        borderRadius: 8
    },
    imageWithTextId: {
        fontSize: '14px',
        marginLeft: 5,
        fontWeight: '400'
    },

    address: {
        fontSize: '14px',
        paddingTop: 10,
        fontFamily: 'Roboto',
        fontWeight: '300'
    },

    products: {
        display: 'flex',
        alignItems: 'top',
        fontFamily: 'Roboto',
        fontSize: '14px',
        paddingTop: 10
    },
    price: {
        paddingTop: 10,
    },
    priceItem: {
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        fontSize: '14px',
        paddingTop: 10,
        textAlign: 'right',
        fontWeight: '500'
    },
    summary: {
        fontSize: '14px',
        fontWeight: '500',
    },
    delivery: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        paddingTop: 10,
    },
    payment: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        paddingTop: 10,
    },
    row: {
        marginTop: 8
    },
    boxDeliveryDate: {
        fontWeight: 300,
        fontSize: 14,
        display: 'block'
    },

    boxDeliveryTitle: {
        fontWeight: 500,
        fontSize: 14
    }

}));

function StaticOrderForm({order, type = 'client', onOpenCommentDialog = null}) {
    const classes = useStyles();
    const options = {  year: 'numeric', month: 'short', day: 'numeric' };

    if (!order) {
        return <Loader/>
    }

    console.log('order contents: ', order);


    function hasBoxOrders(orderItems){

        if(orderItems === null || typeof (orderItems.items) === 'undefined'){
            console.error('Missing order items');
            return false;
        }



        for(let oi of orderItems.items){

            console.log("oi is box diet: ", oi);
            if(oi.is_box_diet){
                return true;
            }
        }

        return false;
    }


    function printBoxDietDeliveryDate(deliveryDate){

        return (
            <span className={classes.boxDeliveryDate}>
                { new Date(deliveryDate).toLocaleDateString(undefined, options) }
            </span>
        )
    }


    function renderBoxDeliveryDates(orderItems, delivered) {
        if(orderItems === null){
            return;
        }

        return(
            orderItems && orderItems.map(oi => {
                return (
                    <div>
                        <div>
                        {printBoxDietDeliveryItemTitle(oi, delivered)}
                        </div>
                        <div>
                            {printBoxDietDeliveryDatesBlock(oi, delivered)}
                        </div>
                    </div>

            )
                    ;
            })
        );

    }


    function printBoxDietDeliveryItemTitle(deliveryItem, delivered){

        let now = new Date().getTime();

        if((delivered && new Date(deliveryItem.dates_range.start_date).getTime() >= now ) || (!delivered && new Date(deliveryItem.dates_range.end_date).getTime() < now) ){
            return;
        }


        var istr = deliveryItem.quantity + " x " + deliveryItem.menu_item.name;

        if(typeof(deliveryItem.dates_range) !== 'undefined' && typeof(deliveryItem.delivery_dates) !== 'undefined' && deliveryItem.delivery_dates.length > 0){
            istr += "(" + new Date(deliveryItem.dates_range.start_date).toLocaleDateString(undefined, options)
                + "-" + new Date(deliveryItem.dates_range.end_date).toLocaleDateString(undefined, options) + ": " + deliveryItem.delivery_dates.length + " dostaw)";
        }

        return (
        <Typography component="p" className={classes.boxDeliveryTitle}>
            {istr}
        </Typography>
        )
    }


    function printBoxDietDeliveryDatesBlock(deliveryItem, delivered){
        if(deliveryItem == null || !deliveryItem.is_box_diet){
            return;
        }

        let now = new Date().getTime();



        return (

         deliveryItem.delivery_dates && deliveryItem.delivery_dates.map(dd => {

             if(delivered && dd < now || !delivered && dd >= now) {
                 return (printBoxDietDeliveryDate(dd))
             }

        })
        )
    }



    return (
        <div className={classes.root}>
            <OrderProgress order={order} type={type}/>

            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Identyfikator
                    </Typography>

                    <div className={classes.imageWithText}>
                        <CardMedia className={classes.imageWithTextCardMedia} image={order.restaurant.image_filename}/>
                        <Typography className={classes.imageWithTextId}>{order.id}</Typography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Podsumowanie
                    </Typography>

                    <div className={classes.products}>
                        <OrderItemProducts items={order.items}/>
                    </div>
                </Grid>
            </Grid>


            {hasBoxOrders(order) &&
            <Grid container spacing={2} className={classes.row}>


                <Grid item xs={12}>
                <Typography component="p" className={classes.header}>
                    Termin dostawy
                </Typography>

                </Grid>

                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Zrealizowane
                    </Typography>

                    {renderBoxDeliveryDates(order.items, true)}


                </Grid>

                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Nadchodzące
                    </Typography>

                    {renderBoxDeliveryDates(order.items, false)}
                </Grid>


            </Grid>
            }


            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Forma dostawy
                    </Typography>
                    <div className={classes.delivery}>
                        {order.delivery ? order.delivery.name : ''}
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Forma płatności
                    </Typography>
                    <div className={classes.payment}>
                        {order.payment.name}
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Adres dostawy
                    </Typography>
                    <div className={classes.address}>
                        {order.address}
                        <Box>tel.{order.phone}</Box>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Zestawienie kosztów
                    </Typography>
                    <div className={classes.price}>
                        <Box className={classes.priceItem}>
                            <Typography component="h3" className={classes.summary}>Koszt produktów</Typography>
                            <Price value={order.price}/>
                        </Box>
                        <Box className={classes.priceItem}>
                            <Typography component="h3" className={classes.summary}>Koszt dostawy</Typography>
                            <Price value={order.delivery_price + order.surcharge_price}/>
                        </Box>
                        <Box className={classes.priceItem}>
                            <Typography component="h3" className={classes.summary}>Łącznie</Typography>
                            <Price value={order.total_price}/>
                        </Box>
                    </div>
                </Grid>
            </Grid>




            {type == 'client' && (
                <div>
                <Grid container spacing={2} className={classes.row}>
                    <Grid item xs={6}>
                        <Typography component="p" className={classes.header}>
                            Kontakt z kucharzem
                        </Typography>
                        <div className={classes.address}>
                            {order.restaurant.user.name}
                            <Box>tel.{order.restaurant.user.phone}</Box>
                        </div>
                    </Grid>
                </Grid>

                    <Grid container spacing={2} direction="row-reverse">
                        <Grid item xs={4}>
                        {order.status === 'confirmed' && (
                            <Button variant="contained" color="primary" aria-label="Podgląd zamówienia" onClick={()=> onOpenCommentDialog(order) }>
                                Oceń tego kucharza
                            </Button>
                        )}
                        </Grid>

                    </Grid>

                </div>
            )}
        </div>
    );
}

export default StaticOrderForm;
