import React from "react";
import {useCartRestaurantTotal, useCartTotal} from "../selector";
import Price from "./Price";
import {Typography, Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {calculateDeliveryPrice} from "../services/restaurantService";

const useStyles = makeStyles((theme) => ({
    card: {
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        marginTop: 15,
        marginBottom: 15,
        padding: 15,
        borderRadius: 0,
        paddingTop: 30,
        paddingBottom: 30,
        '& > p': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            font: 'normal normal bold 20px/21px Roboto',
            letterSpacing: '0.18px',
            color: '#757575',
            fontWeight: '600'
        }
    },
    deliveryPrice: {
        fontSize: '16px !important',
        fontWeight: 'normal !important'
    }
}));

function RestaurantOrdersAmount({restaurant, delivery = null}) {
    const classes = useStyles();
    const amount = useCartRestaurantTotal(restaurant.id);

    const deliveryPrice = (delivery && delivery === 'house') ? calculateDeliveryPrice(restaurant, amount) : 0;
    const total = amount + deliveryPrice;

    return (
        <Box className={classes.card}>
            <Typography component="p">Razem: <Price value={total}/></Typography>
            {delivery && delivery === 'house' && (
                <Typography component="p" className={classes.deliveryPrice}>W tym koszt dostawy: <Price value={deliveryPrice}/></Typography>
            )}
        </Box>
    );
}

export default RestaurantOrdersAmount;
