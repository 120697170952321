import React from "react";

function Price({value}) {

    return (
        <span>
            {parseFloat(value).toFixed(2)} ZŁ
        </span>
    );
}

export default Price;
