import {initialState} from "./index";


export function addRestaurantIdReducer(state = initialState.restaurantId, action) {
    switch (action.type) {
        case "ADD_RESTAURANT_ID": {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function restaurantsReducer(state = initialState.restaurants, action = {}) {
    switch (action.type) {
        case "LIST_RESTAURANTS": {
            return action.payload.data;
        }

        case "FETCH_RESTAURANT": {
            return {
                ...state,
                restaurant: action.payload
            };
        }

        default: {
            return state;
        }
    }
}