import {useUser} from "../../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import GeoAutoCompleteInput from "../../components/GeoAutoCompleteInput"
import HeaderBar from "../../components/HeaderBar";
import {showSuccessSnackbar} from "../../actions/ui";
import {navigate, Redirect} from "@reach/router";
import {useFetchProfile} from "../../actions";
import {getProfile, updateDelivery} from "../../services/userService";
import {hasError} from "../../services/validators";
import Box from "@material-ui/core/Box";
import GeoStaticMap from "../../components/GeoStaticMap";
import Auth from "./Auth";
import {getOrders} from "../../services/orderService";
import BoxLayout from "../../components/Layouts/BoxLayout";
import Footer from "../Footer";
import {parseQuery, useQuery} from "../../services/localizationService";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h1: {
        textAlign: 'center',
        font: ' normal normal bold 24px/24px Roboto',
        letterSpacing: '0.23px',
        color: '#FA4A0C'
    }
}));

function Delivery(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [from, setFrom] = React.useState(useQuery().get('from'));

    const [errors, setErrors] = React.useState({});
    const [user, setUser] = useState(null);
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    React.useEffect(() => {
        console.log('[Delivery] reloadUser');
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        if (user && user.delivery && user.delivery[0]) {
            console.log('test');
            setFormValues(user.delivery[0]);
        }
    }, [user]);

    function handleInputChange(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleInputAddressChange(event, data) {
        console.log('[AC]: addressChange', data);

        setFormValues({
            ...formValues,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,

            city: data.city,
            state: data.state,
            country: data.country
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateDelivery(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                dispatch(showSuccessSnackbar("Adres dostawy został zmieniony!"));

                if (from) {
                    navigate('/order');
                }
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <Box>
            <HeaderBar routePath="/profile/delivery" titleBar=""/>
            <BoxLayout>
                <Auth>
                    <CssBaseline/>

                    <form onSubmit={handleSubmit} className={classes.form}>
                        <h1 className={classes.h1}>Adres dostawy</h1>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                label="Imię i nazwisko"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={formValues.name || ''}
                                onChange={handleInputChange}
                                error={hasError('name', errors)}
                                helperText={
                                    hasError('name', errors) ? errors.name[0] : null
                                }
                            />

                            <GeoAutoCompleteInput
                                label={'Adres dostawy'}
                                defaultValue={formValues.address || ''}
                                onChange={handleInputAddressChange}
                                error={hasError('address', errors)}
                                helperText={
                                    hasError('address', errors) ? errors.address[0] : null
                                }
                            />

                            <Box pt={1} pb={1}>
                                <GeoStaticMap
                                    id="google-map"
                                    position={
                                        {
                                            lat: formValues.latitude,
                                            lng: formValues.longitude
                                        }
                                    }
                                    style={{width: '400px', height: '300px'}}/>
                            </Box>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.submit}
                                disabled={isFormProcessed}
                            >
                                {!isFormProcessed ? <Box>Potwierdź adres</Box> : <Box>Wysyłanie...</Box>}
                            </Button>

                        </form>
                </Auth>
            </BoxLayout>
            <Footer/>
        </Box>
    );
}

export default Delivery;
