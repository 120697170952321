import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HeaderBar from "../components/HeaderBar";
import Background from '../assets/bg.png';

import BgLp1 from '../assets/lp/bg/main.png';
import BgLp2 from '../assets/lp/bg/zuck.png';
import BgLp3 from '../assets/lp/bg/vege.png';

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {navigate} from "@reach/router";
import {getQueryLink} from "../services/localizationService";
import {useDispatch} from "react-redux";
import Footer from "./Footer";
import Box from "@material-ui/core/Box";
import LandingPageForm from "../components/Profile/Forms/LandingPageForm";
import {getLandingName} from "../services/mainService";
import Lp1 from "../components/Landings/Lp1";
import Lp2 from "../components/Landings/Lp2";
import Lp3 from "../components/Landings/Lp3";

const useStyles = makeStyles((theme) => ({
    page: {
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",

        [theme.breakpoints.up('md')]: {
            maxHeight: '662px',
            height: '662px',
        },
    },
    lp1: {
        backgroundImage: `url(${BgLp1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",

        [theme.breakpoints.up('md')]: {
            maxHeight: '662px',
            height: '662px',
        },
    },
    lp2: {
        backgroundImage: `url(${BgLp2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",

        [theme.breakpoints.up('md')]: {
            maxHeight: '662px',
            height: '662px',
        },
    },
    lp3: {
        backgroundImage: `url(${BgLp3})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",

        [theme.breakpoints.up('md')]: {
            maxHeight: '662px',
            height: '662px',
        },
    },
    pageWrapper: {
        position: 'relative',
        height: '100%'
    },
    pageOverlay: {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.5,
        zIndex: 0,
        position: "absolute",
        /*maxHeight: '410px',*/

        minHeight: '662px',

        [theme.breakpoints.down('md')]: {
            minHeight: '562px'
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '462px'
        },
    },
    pageContainer: {
        zIndex: 1,
        position: "relative"
    },

    pageIntro: {

    },

    orderFaster: {
        marginTop: -65,

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },

    orderFasterMobile: {
        display: 'none',
        width: '100%',

        [theme.breakpoints.down('md')]: {
            marginTop: 20,
            display: 'block'
        },
    },

    deliveryDiningIcon: {
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            display: 'block',

            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },

    whitePageBoxForm: {

        textAlign: 'center',

        marginTop: '50px',
        marginBottom: '90px',
        padding: 45,
        zIndex: 1,

        background: '#FFFFFF',
        boxShadow: '-4px 4px 22px rgba(0, 0, 0, 0.15)',
        borderRadius: '20px',

        '& h2': {
            font: 'normal normal bold 24px/18px Roboto',
            letterSpacing: '0.8px',
            color: '#757575',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '&>p': {
            font: 'normal normal normal 18px/22px Roboto',
            color: '#757575',
            letterSpacing: '0.6px',
            marginTop: '15px',
            marginBottom: '15px'
        },
    },

    footer: {
        background: '#2D2D2D',

        paddingTop: 30,
        paddingBottom: 30,

        '& a': {
            color: 'white',
            display: 'block',
            font: 'normal normal normal 14px/18px Roboto',
            letterSpacing: '0.47px',
            paddingTop: '5px',
            paddingBottom: '5px'
        }
    }
}));

export default function LandingPage({path}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    console.log('path', path);

    const landing = getLandingName(path);

    console.log('landingName', landing);

    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    function handleClick(event) {
        submit(formValues);
    }

    function submit(data) {
        navigate(getQueryLink(data));
    }

    function getLandingBgClassName() {
        if (landing === 'lp1') {
            return classes.lp1;
        } else if (landing === 'lp2') {
            return classes.lp2;
        } else if (landing === 'lp3') {
            return classes.lp3;
        }
        return null;
    }

    return (
        <div>
            <HeaderBar routePath="/home"/>

            <div className={getLandingBgClassName()}>
                <div className={classes.pageWrapper}>
                    <div className={classes.pageOverlay}></div>

                    <Container component="main" maxWidth="lg">
                        <Grid container className={classes.pageContainer}>
                            <Grid item lg={8} md={12}>
                                {landing === 'lp1' && (
                                    <Lp1 type={"header"} className={classes.pageIntro}/>
                                )}

                                {landing === 'lp2' && (
                                    <Lp2 type={"header"} className={classes.pageIntro}/>
                                )}

                                {landing === 'lp3' && (
                                    <Lp3 type={"header"} className={classes.pageIntro}/>
                                )}

                            </Grid>
                            <Grid item lg={4} md={12}>
                                <Grid item className={classes.whitePageBoxForm}>

                                    <Typography variant={"h2"} component={"h2"}>Skontaktuj się z nami</Typography>

                                    {!isFormProcessed && (
                                        <LandingPageForm landing={landing} hideSubmitButton={false} onSuccess={() => setIsFormProcessed(true)} onError={() => setIsFormProcessed(false)}/>
                                    )}

                                    {isFormProcessed && (
                                        <Box>
                                            <Typography variant={"p"} component={"p"}>Dziękuje i odezwiemy się wkrótce!</Typography>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <Lp1 type={"description"}/>

                <Footer/>
            </div>
        </div>
    );
}
