
function getDaysOff(days){
    console.log('to process ', days);

    if(days === null){
        return [];
    }

    var offDays = [];

    if(isDayOff(days.sunday)) {
        offDays.push(0);
    }

    if(isDayOff(days.monday)){
        offDays.push(1);
    }

    if(isDayOff(days.tuesday)){
        offDays.push(2);
    }


    if(isDayOff(days.wednesday)){
        offDays.push(3);
    }

    if(isDayOff(days.thursday)) {
        offDays.push(4);
    }

    if(isDayOff(days.friday)){
        offDays.push(5);
    }

    if(isDayOff(days.saturday)){
        offDays.push(6);
    }


    return offDays;
}

function isDayOff(day){
    return !day.open;
}

export default getDaysOff;