import React, {useState} from "react";
import {navigate, useParams} from "@reach/router";
import {useUser} from "../selector";
import HeaderBar from "../components/HeaderBar";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import ajaxClient from "../services/ajaxClient";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from '@material-ui/core/Link';
import RestaurantMenuItem from "../components/RestaurantMenuItem";
import {useAddRestaurantId, useAddBoxDiet} from "../actions";
import Footer from "./Footer";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MiniCart from "../components/MiniCart";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {loginRestaurant} from "../services/userService";
import {showSuccessSnackbar} from "../actions/ui";
import HeaderOverlay from "../components/HeaderOverlay";

import getDaysOff from '../middlewares/restaurantDays';

import BoxDietDatesSelectDialog from "../components/BoxDiet/BoxDietDatesSelectDialog";

import RatingInfo from '../components/RatingInfo/RatingInfo'

import Comment from '../components/Comment/Comment';

import RatingsList from '../components/RatingsList/RatingsList';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    miniCart: {
        position: 'sticky',
        top: 90
    },
    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    breadcrumbLink: {
        fontSite: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.15px',
        color: '#757575'
    },
    breadcrumbActiveLink: {
        fontSite: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.15px',
        fontWeight: 'bold',
        color: '#757575'
    },
    restaurant__name: {
        font: 'normal normal normal 34px/41px Roboto',
        letterSpacing: '1.13px',
        color: '#757575',
        marginTop: '30px'
    },
    restaurant__description: {
        font: 'normal normal normal 16px/18px Roboto',
        letterSpacing: '0.53px',
        color: '#757575',
        marginTop: '15px',
        marginBottom: '15px',
        whiteSpace: 'pre-line'
    },
    restaurant__header: {
        width: '100%',
        height: '40vh',
        maxHeight: '600px',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    restaurant__header_image: {
        width: '100%',
        height: 'auto',
    },
    restaurant__header_image_text: {
        position: 'absolute',
        marginBottom: 60,
        fontSize: 60,
        fontWeight: 'bold',
        color: 'white',
        letterSpacing: 2,

    },
    restaurant__container: {
        zIndex: 2,
        position: 'relative',
        marginTop: -80,
        marginBottom: 30
    },
    restaurant__title_section: {
        padding: theme.spacing(4)
    }
}));


function Restaurant() {
    const params = useParams();
    const classes = useStyles();

    const user = useUser();
    const dispatch = useDispatch();

    const [restaurant, setRestaurant] = useState([]);

    const [daysOff, setDaysOff] = useState([]);

    const [currentBoxDiet, setCurrentBoxDiet] = useState([]);



    const AddBoxDiet = useAddBoxDiet();

    const [avgRating, setAvgRating] = useState(0);

    const [numRatingVotes, setNumRatingVotes] = useState(0);

    const [showRatingsList,setShowRatingsList] = useState(false);

    const [ratings, setRatings] = useState([]);



    React.useEffect(() => {
        async function fetchRestaurant() {
             await ajaxClient().get("restaurants/" + params.slug)
                 .then(response => {
                     setRestaurant(response.data.data);
                     setDaysOff(getDaysOff(response.data.data.opening_hours));
                     setAvgRating(response.data.data.avg_rating);
                     setNumRatingVotes(response.data.data.num_rating_votes);

                 })
            ;

        }

        fetchRestaurant();
    }, []);

    function buildRandom(){
        let min = 1.0;
        let max = 5.0;
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
/*
    const ratings = [
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        },
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        }
        ,{
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        },
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        },
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        },
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        },
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        },
        {
            commentTitle : 'Test komentarza',
            author : 'Jakiś autor',
            date: new Date().toLocaleDateString(),
            content: 'Zawartość komentarza. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit lacus neque. Duis tincidunt venenatis neque, ac imperdiet dolor porta at. Nulla porttitor id erat eget euismod. Suspendisse euismod libero ut mauris faucibus tempor. ',
            rating: buildRandom()
        }
    ]; */


    function onBoxDietSelectClicked(menuItem){
        setCurrentBoxDiet(menuItem);
    }

    function onBoxDietDialogClosed(){
        setCurrentBoxDiet(null);
    }

    function onDatesRangeSelected(dateStart, dateEnd, deliveryDates){
        if(currentBoxDiet !== null) {
            AddBoxDiet(currentBoxDiet, {
                    startDate: dateStart.getTime(),
                    endDate: dateEnd.getTime()
                },
                deliveryDates
            );
        } else {
            console.log('Menu item is null!');
        }
    }



    async function onRatingClick(){

        if(restaurant === null){
            console.log('null restaurant');
            return;
        }

        await ajaxClient().get("restaurant/comments/" + restaurant.id).then(resp => {
            console.log('ratings: ' + JSON.stringify(resp));
            setRatings(resp.data.data);
            setShowRatingsList(true);
        });
    }




    function handleRestaurantLogin(restaurantId) {
        loginRestaurant(restaurantId).then(response => {

            let data = response.data;

            if (data && data.error) {
                //setErrors(data.error);
                dispatch(showSuccessSnackbar("Wystąpił błąd"));
            } else {

                localStorage.session = data.token;
                dispatch({type: "LOGIN", payload: data});
                dispatch(showSuccessSnackbar("Zostałeś zalogowany!"));
            }
        });
    }

    let city = 'city',
        country = 'country';

    if (!restaurant) {
        return <div>Ładowanie...</div>
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/restaurants" titleBar="Restaurant"/>

            <HeaderOverlay></HeaderOverlay>



            <Container maxWidth="lg" className={classes.restaurant__container}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                >
                    <Grid item md={8} sm={8} xs={12}>
                        <Card className={classes.card}>
                            <CardActionArea>
                                <CardContent className={classes.restaurant__title_section}>
                                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbLink}
                                                 separator={<NavigateNextIcon fontSize="small"/>}>
                                        <Link color="inherit" href="/" to={'/'}>
                                            Strona główna
                                        </Link>
                                        <Link
                                            color="inherit"
                                            href={`/${country}/${city}/restaurants`}
                                            onClick={() => navigate(`/${country}/${city}/restaurants`)}
                                        >
                                            Kucharze
                                        </Link>

                                        <Link
                                            color="textPrimary"
                                            href={`${restaurant.url}`}
                                            onClick={() => navigate(`${restaurant.url}`)}
                                            aria-current="page"
                                            className={classes.breadcrumbActiveLink}
                                        >
                                            {restaurant.name}
                                        </Link>

                                    </Breadcrumbs>

                                    <Typography className={classes.restaurant__name}>
                                        {restaurant.name}
                                    </Typography>
                                    <Typography className={classes.restaurant__description}>
                                        {restaurant.description}
                                    </Typography>

                                    <RatingInfo
                                        rating={avgRating}
                                        numVotes={numRatingVotes}
                                        isClickable={false}
                                        onStarsClick = {(event) => onRatingClick(event)}
                                        onClick = {(event) => onRatingClick(event)}
                                    />
                                    {/*<Typography gutterBottom variant="h5" component="h2">
                                        <StarIcon/>
                                        <StarIcon/>
                                        <StarIcon/>
                                        <StarIcon/>
                                        <StarIcon/>
                                    </Typography>*/}
                                </CardContent>
                            </CardActionArea>
                        </Card>

                        {restaurant && user && (user.type === 'admin') && restaurant.id && (
                            <Box>
                                <Button onClick={() => handleRestaurantLogin(restaurant.id)} className={classes.button}>Zaloguj R{restaurant.id}</Button>
                            </Box>
                        )}

                        {restaurant && restaurant.collections && restaurant.collections.map(collection => {

                            if (restaurant.menu_items.some(menuItem => menuItem.menu_collection_id === collection.id)) {
                                return (
                                    <Box mt={3} mb={5}>

                                        <Box mb={1}>
                                            <Typography gutterTop variant="h5" component="h3" color="textSecondary">
                                                {collection.name}
                                            </Typography>
                                        </Box>

                                        <Grid container spacing={2} direction="row" alignItems="flex-start">
                                            {restaurant.menu_items && restaurant.menu_items.filter(menuItem => menuItem.menu_collection_id === collection.id).map(menuItem => {
                                                return (
                                                    <Grid item md={6} sm={6} xs={12} key={"menuItem" + menuItem.id}>
                                                        <RestaurantMenuItem menuItem={menuItem} onBoxDietSelectClick={onBoxDietSelectClicked}/>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                )
                            }
                        })}

                        {restaurant && restaurant.menu_items && restaurant.menu_items.some(menuItem => menuItem.menu_collection_id === null) && (
                            <Box mt={3} mb={5}>
                                <Box mb={1}>
                                    <Typography gutterTop variant="h5" component="h3" color="textSecondary">
                                        Pozostałe
                                    </Typography>
                                </Box>

                                <Grid container spacing={2} direction="row" alignItems="flex-start">
                                    {restaurant.menu_items && restaurant.menu_items.filter(menuItem => !menuItem.menu_collection_id).map(menuItem => {
                                        return (
                                            <Grid item md={6} sm={6} xs={12} key={"menuItem" + menuItem.id}>
                                                <RestaurantMenuItem menuItem={menuItem} onBoxDietSelectClick={onBoxDietSelectClicked}/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        )}

{/*
                        {restaurant.menu_items && restaurant.menu_items.map(value => {
                            return (
                                <Grid item md={6} sm={6} xs={12} key={value.id}>
                                    <RestaurantMenuItem menuItem={value}/>
                                </Grid>
                            )
                        })}*/}
                    </Grid>
                    <Grid item md={4} sm={4} xs={12} className={classes.miniCart}>
                        <MiniCart restaurant={restaurant} showOrderButton={true}/>
                    </Grid>
                </Grid>
            </Container>
            <Footer/>


            {currentBoxDiet !== null && currentBoxDiet.is_box_diet === 1 &&

            <BoxDietDatesSelectDialog
                isOpen={currentBoxDiet !== null}
                onDialogClosed={onBoxDietDialogClosed}
                daysOff={daysOff}
                onDatesRangeSelected={onDatesRangeSelected}
                currentDatesRange = {null}
                unitPrice={currentBoxDiet !== null ? currentBoxDiet.price : 0}
            />



            }





            <RatingsList
            companyName={restaurant.name}
            ratings={ratings}
            companyRating={avgRating}
            numRatings={numRatingVotes}
            showRatingsList={showRatingsList}
            setShowRatingsList={setShowRatingsList}
            />

        </div>
    );
}

export default Restaurant;
