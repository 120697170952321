import React from "react";
import './HeaderBar.css';

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Background from "../assets/bg.png";


const useStyles = makeStyles((theme) => ({
    restaurant__header: {
        width: '100%',
        height: '40vh',
        maxHeight: '600px',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',

        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
    },
    pageOverlayHeader: {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.1,
        zIndex: 0,
        position: "absolute",
        maxHeight: '40vh'
    },
    restaurant__header_image: {
        width: '100%',
        height: 'auto',
    },
    restaurant__header_image_text: {
        position: 'absolute',
        marginBottom: 60,
        fontSize: 60,
        fontWeight: 'bold',
        color: 'white',
        letterSpacing: 2,

        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
    },
}));


function HeaderOverlay() {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <section className={classes.restaurant__header}>
                <div className={classes.pageOverlayHeader}></div>
                {/*{restaurant && (
                            <img src={restaurant.image_filename} className={classes.restaurant__header_image}/>
                        )}*/}
                {/*{restaurant && (
                            <img src={Background} className={classes.restaurant__header_image}/>
                        )}*/}
                <Typography component="h1" variant="h1" className={classes.restaurant__header_image_text}>Twoje domowe posiłki</Typography>
            </section>
        </div>
    );
}

export default HeaderOverlay;
