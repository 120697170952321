import ajaxClient from "../services/ajaxClient";
import {navigate} from "@reach/router";

function logout() {
    return async dispatch => {
        ajaxClient().post("logout")
            .then(response => {
                console.log('dispatch logout');
                dispatch({ type: "LOGOUT" });
                localStorage.session = null;

                navigate("/");
            })
            .catch(error => {
                console.log('dispatch logout error');
                dispatch({ type: "LOGOUT" });
                localStorage.session = null;
                navigate("/");
            });
    };
}

function login(user) {
    return async dispatch => {
        ajaxClient().post("login", {
            'email': user.email,
            'password': user.password
        })
            .then(response => {
                const payload = response.data;
                localStorage.session = response.data.token;
                dispatch({ type: "LOGIN", payload });
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    const payload = error.response.data;
                    dispatch({ type: "LOGIN_ERRORS", payload });
                } else {
                    throw(error);
                }
            });
    };
}

function fetchProfile() {
    return async dispatch => {
        if (!localStorage.session) {
            dispatch({ type: "LOGOUT" });
            return null;
        }

        ajaxClient().get("profile")
            .then(response => {
                const payload = response.data.data;
                dispatch({ type: "PROFILE", payload });
            })
            .catch(error => {
                console.log('usr err:' + error);
                if (error.response && error.response.data && error.response.data.error) {

                    const payload = error.response.data;
                    dispatch({ type: "PROFILE_ERRORS", payload });
                } else {
                    throw(error);
                }
            });
    };
}

export {
    logout,
    login,
    fetchProfile
};

