import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Link} from "@reach/router";
import Box from "@material-ui/core/Box";
import RatingInfo from "./RatingInfo/RatingInfo";

const useStyles = makeStyles({
    card: {
        borderRadius: 10,
        width: '100%',
        transition: '.3s',
        '&:hover': {
            willChange: 'transform',
            transform: 'scale(1.04)',
            boxShadow: '2px 4px 40px 0 rgba(0,0,0,.08)',
            animationTimingFunction: 'cubic-bezier(.075,.82,.165,1)'
        },

        minHeight: 270
    },

    restaurant_url: {
      textDecoration: 'none',
        color: '#757575'

    },
    cardContent: {
        padding:'15px',
    },
    restaurant_name: {
        font: 'normal normal bold 16px/29px Roboto',
        letterSpacing: '0.14px',
        color: '#757575',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'

    },
   restaurant_description:{
       font: 'normal normal normal 16px/29px Roboto',
       letterSpacing: '0.14px',
        color: '#757575',
       marginTop: '9px',
       whiteSpace: 'nowrap',
       overflow: 'hidden',
       textOverflow: 'ellipsis'
   }
});

export default function Restaurant({restaurant}) {
    const classes = useStyles();


    return (
        <Link to={`${restaurant.url}`} className={classes.restaurant_url}>
            <Card className={classes.card}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={restaurant.name}
                        height="170"
                        image={restaurant.image_filename}
                        title={restaurant.name}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.restaurant_name}>
                            {restaurant.name}
                            {/*{restaurant.opened && (
                                <Box>Czynne teraz</Box>
                            )}*/}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.restaurant_description}>
                            {restaurant.categories && restaurant.categories[0] && (
                                <Box component={'span'}>{restaurant.categories[0].name}</Box>
                            )}

                            {restaurant.categories && restaurant.categories[1] && (
                                <Box component={'span'}> • {restaurant.categories[1].name}</Box>
                            )}
                        </Typography>


                        <RatingInfo rating={restaurant.avg_rating} numVotes={restaurant.num_rating_votes} max={1} />

                    </CardContent>
                </CardActionArea>
            </Card>
            <br/>
        </Link>
    );
}
