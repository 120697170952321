import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography'
import Rating from "@material-ui/lab/Rating/Rating";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";



import {CommentDiv} from './styles/styles';

export default function Comment( {company,  isOpen = false, setIsOpen, onCommentSubmitted } ){




    var data = {
        'company' : company
    };


    return (<Dialog
        open={isOpen}

    >

        <DialogTitle>Oceń "{ ( company === null || company.name === null ? '' : company.name ) }"</DialogTitle>
        <DialogContent>
            <CommentDiv>
            <Typography

                component="legend"
                variant="p"
            >
                Twoja ocena
            </Typography>

            <Rating
                onChange = {(event, val) => data.rating = val}
            />
            </CommentDiv>



            <CommentDiv>
            <TextField
                variant="outlined"
                multiline={true}
                minRows={4}
                rows={4}
                fullWidth
                onChange = {(event) => data.comment = event.target.value}
                label='Treść'
            >
            </TextField>
            </CommentDiv>

        </DialogContent>

        <DialogActions>

            <Button
                variant="contained"
                onClick={() => setIsOpen(false)}
                color="secondary"
            >
                Anuluj
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    setIsOpen(false);
                    onCommentSubmitted(data);
                }}
                color="default"
            >
                Potwierdź
            </Button>

        </DialogActions>

    </Dialog>)
}


