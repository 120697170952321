import React from "react";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    breadcrumbLink: {
        fontSite: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.15px',
        color: '#757575'
    },
    breadcrumbActiveLink: {
        fontSite: '16px',
        fontFamily: 'Roboto',
        letterSpacing: '0.15px',
        fontWeight: 'bold',
        color: '#757575'
    }
}));

function HeaderBreadcrumb({name, url}) {
    const classes = useStyles();

    return (
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbLink} separator={<NavigateNextIcon fontSize="small" />}>
            <Link color="inherit" href="/">
                Strona główna
            </Link>

            <Link color="inherit" href={url} aria-current="page" className={classes.breadcrumbActiveLink}>
                {name}
            </Link>
        </Breadcrumbs>
    );
}

export default HeaderBreadcrumb;
