import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const CustomDialogTitle = (props) => {
    const { children, handleClose} = props;
    return (
        <DialogTitle id="responsive-dialog-title">
            {children}

            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};
export default CustomDialogTitle;
