import React from "react";
import Restaurant from "./views/Restaurant";
import Restaurants from "./views/Restaurants";
import {Router} from "@reach/router";
import Login from "./views/Login";
import Register from "./views/Register";
import Record from "./views/Record";
import Order from "./views/Order";
import Home from "./views/Home";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Delivery from "./views/Profile/Delivery.js";
import Personal from "./views/Profile/Personal.js";
import SuccessSnackbar from "./components/SuccessSnackbar";
import NoSsr from "@material-ui/core/NoSsr";
import {ThemeProvider} from '@material-ui/core/styles';
import Test from "./views/Test";
import Contact from "./views/Profile/Contact";
import MenuEmpty from "./views/Profile/MenuEmpty.js";
import Menu from "./views/Profile/Menu.js";
import MenuRestaurant from "./views/Profile/MenuRestaurant";
import LandingPage from "./views/LandingPage";

//require('dotenv').config();
import OrderTracking from "./views/Profile/OrderTracking";
import Orders from "./views/Profile/Orders";
import RestaurantOrders from "./views/Profile/RestaurantOrders";
import theme from "./Theme";
import Page from "./views/Page";
import RegisterCook from "./views/RegisterCook";
import ConfirmPhoneNumber from "./views/ConfirmPhoneNumber";
import Company from "./views/Profile/Company";
import RestaurantCompany from "./views/Profile/RestaurantCompany";
import {CssBaseline} from "@material-ui/core";

function App() {
    return (
        <NoSsr>
            <ThemeProvider theme={theme}>
                <div>
                    <CssBaseline />
                    <SuccessSnackbar/>
                    <Router>
                        <Home path="/"/>

                        <Login path="/login"/>
                        <Register path="/register"/>
                        <RegisterCook path="/register-cook"/>
                        <ForgotPassword path="/forgot-password"/>
                        <ResetPassword path="/reset-password/:token"/>
                        <ConfirmPhoneNumber path="/profile/confirm-phone" />
                        <Restaurants path="/restaurants"/>

                        <Restaurants path="/s/:query"/>

                        {/*<Restaurants path="/:country/:city/restaurants"/>
                        <Restaurants path="/:country/:city/restaurants/category/:categorySlug"/>
                        */}

                        <Restaurant path="/:country/:city/:slug"/>

                        <Record path="/record"/>
                        <Order path="/order"/>

                        <Company path="/profile/company"/>
                        <Delivery path="/profile/delivery"/>
                        <Contact path="/profile/contact"/>

                        <Personal path="/profile/personal"/>  
                        <MenuEmpty path="/profile/menu/empty"/> 
                        <Menu path="/profile/menu"/>
                        <MenuRestaurant path="/profile/menu/restaurant"/>
                        <Test path="/test"/>

                        <Orders path="/profile/orders"/>
                        <Orders path="/profile/orders/:id"/>
                        <RestaurantOrders path="/profile/restaurant/orders"/>
                        <RestaurantCompany path="/profile/restaurant/company"/>

                        <OrderTracking path="/profile/tracking/"/>
                        <OrderTracking path="/profile/tracking/:id"/>

                        <Page path="/warunki-i-postanowienia"/>
                        <Page path="/problem-z-zamowienie"/>
                        <Page path="/kontakt"/>
                        <Page path="/jak-zostac-kucharzem"/>
                        <Page path="/regulamin"/>
                        <Page path="/polityka-prywatnosci"/>
                        <Page path="/jak-korzystac"/>

                        <LandingPage path="/lp1"/>
                        <LandingPage path="/lp2"/>
                        <LandingPage path="/lp3"/>

                    </Router>
                </div>
            </ThemeProvider>
        </NoSsr>
    );
}

export default App;
