import ajaxClient from "../services/ajaxClient";

const API_RESTAURANT_URL = "http://127.0.0.1:8000/api/restaurants";

function addRestaurantId(id) {
    return {
        type: "ADD_RESTAURANT_ID",
        payload: id
    };
}

function fetchRestaurant(id) {
    return async dispatch => {
        ajaxClient().get(`restaurants/${id}`)
            .then(response => {
                const payload = response.data.data;
                dispatch({ type: "FETCH_RESTAURANT", payload });
            })
            .catch(error => {
                dispatch({ type: "DEFAULT" });
            });
    };
}

/*
const addRestaurantId = id => {
    return dispatch => {
        dispatch({ type: "ADD_RESTAURANT_ID", id });
    };
};

function setRestaurantId(id) {
    return {
        type: "ADD_RESTAURANT_ID",
        payload: id
    };
}
*/

function listRestaurants() {
    return async dispatch => {
        ajaxClient().get(API_RESTAURANT_URL)
            .then(response => {
                const payload = response.data;
                dispatch({ type: "LIST_RESTAURANTS", payload });
            })
            .catch(error => {
                dispatch({ type: "DEFAULT" });
            });
    };
}

export {
    listRestaurants,
    addRestaurantId,
    fetchRestaurant
};
