import {navigate} from "@reach/router";
import {useUser} from "../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import ajaxClient from "../services/ajaxClient";
import {useDispatch} from "react-redux";
import HeaderBar from "../components/HeaderBar";
import {Paper} from "@material-ui/core";
import GeneralError from "../components/GeneralError";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoginFooter from "./LoginFooter";
import Footer from "./Footer";
import BoxLayout from "../components/Layouts/BoxLayout";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        marginBottom: '60.2px',
        paddingBottom:'1px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    h1: {
        marginTop:'-100px',
        textAlign: 'center',
        color: '#FA4A0C',
        fontSize: '24 px'
    },

    fb_button: {
        textTransform: "none",
        border: '1px solid #757575',
        boxShadow: 'unset',
        padding: '5px 15px',

        backgroundColor: '#3F5AA9',
        color: '#FFFFFF',
        borderRadius: 6,

        '&:hover': {
            backgroundColor: '#3F5AA9',
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    body1:{
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0.15px',
        color: '#757575'

    },

    login:{
        '& a':{
            font: 'normal normal bold 20px/28px Roboto',
            letterSpacing: '0.19px',
            color: '#757575',
        },
        marginTop: '38px',
        textAlign: 'center',
    },
    body2:{
        font: 'normal normal normal 16px/28px Roboto',
        letterSpacing: '0.15px',
        color: '#757575'

    },
    lf:{

    }

}));

function ForgotPassword() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState("");
    const user = useUser();

    const [errors, setErrors] = React.useState({});
    const [submitted, setSubmitted] = React.useState(false);
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    React.useEffect(() => {
        if (user.name) {
            navigate("/");
        }
    }, [user]);

    function changeEmail(e) {
        setEmail(e.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        resetPassword({email});
    }

    function resetPassword(user) {
        ajaxClient().post("forgot-password", {
            'email': user.email
        })
            .then(response => {
                /*const payload = response.data;
                localStorage.session = response.data.token;
                dispatch({ type: "LOGIN", payload });*/
                setSubmitted(true);
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    setErrors(error.response.data);
                } else {
                    throw(error);
                }
            });
    }


    const hasError = (field) => errors[field] ? errors[field] : null;

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/home"/>
            <BoxLayout>
            <div className={classes.overlay}/>

            <div className={classes.paper} id="form-content">
                {!submitted &&( <form onSubmit={handleSubmit}>

                    {errors && (
                        <div>{errors.error}</div>
                    )}
                    <div className={classes.h1}>
                        <h1>Przypomnij hasło</h1>
                    </div>
                    <GeneralError errors={errors}/>
                    <div className={classes.textField}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Adres email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={changeEmail}
                        error={hasError('email')}
                        helperText={
                            hasError('email') ? errors.email[0] : null
                        }
                    />
                    </div>


                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={classes.submit}
                        >
                            Przypomnij hasło
                        </Button>

                    <Button

                        fullWidth
                        variant="contained"
                        className={classes.fb_button}
                    >
                        Logowanie przez Facebook
                    </Button>

                    <Box className={classes.login}>
                        <Typography component="h2" className={classes.body1}>
                            Nowy użytkownik?
                        </Typography>

                        <Link href="/register" variant={'body2'}>
                            Załóż konto
                        </Link>

                    </Box>




                </form> )}

                {submitted &&(
                    <div className="welcomeSubscribe">
                        <div>Link do zmiany hasła został wysłany</div>
                        <Link href="/" variant="body2">
                            Powrót do strony głównej
                        </Link>
                    </div>

                )}
                <LoginFooter/>
            </div>
            </BoxLayout>
            <Footer/>
        </div>


    );
}

export default ForgotPassword;
