import React from 'react';
import {RatingContainer, RatingText, RatingStars} from './styles/styles'


export default function RatingInfo({rating = 0, numVotes = 0, isClickable = false, onStarsClick, onClick, max=5}){


    return (<div onClick={onClick}>
        <RatingContainer>
    <RatingText>{parseFloat(rating).toFixed(1)}</RatingText>
    <RatingStars
        size='small'
        readOnly={ !isClickable }
        value={rating}
        onClick = {(event) => onStarsClick(event)}
        max={max}
        precision={0.1}
    />
        {numVotes >= 0 && (
        <RatingText>({numVotes})</RatingText>
        )}
        </RatingContainer> </div>)

}